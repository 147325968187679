import ApplicationController from "controllers/application_controller"

const stripeStyle = {
  base: {
    color: "#32325d",
    fontFamily: '"CerebriSans-Regular", Helvetica, sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#818181"
    }
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a"
  }
};

export default class extends ApplicationController {
  static targets = [
    'card',
    'nameOnCard',
    'cardFormGroup',
    'nameFormGroup'
  ];

  connect() {
    this.onConnect();

    if (Wordable.config.STRIPE_PUBLIC_KEY === null) return;

    this.stripe = Stripe(Wordable.config.STRIPE_PUBLIC_KEY);
    let elements = this.stripe.elements();

    this.stripeCard = elements.create("card", { style: stripeStyle });
    this.stripeCard.mount(this.cardTarget);
    this.stripeCard.addEventListener("change", this.cardNumberChanged);
    this.$nameOnCard.on("change", this.nameOnCardChanged);
    this.$element.dispatch("stripe_card_input_ready", { bubbles: true });
  }

  nameOnCardChanged(e) {
    this.validate();
  }

  validate() {
    const value = this.$nameOnCard.val().trim().replaceAll(/\s+/g, ' ');

    if(value.split(' ').length < 2) {
      this.onCardFormSubmitError({ nameErrorMessage: 'This field is required' });
      this.enableElement();
      return false;
    }

    this.setErrorMessages({ nameErrorMessage: null });
    return true;
  }

  cardNumberChanged(e) {
    if (e?.error?.message) {
      this.setErrorMessages({ cardErrorMessage: e.error.message });
    } else {
      this.setErrorMessages({ cardErrorMessage: null });
    }
  }

  submit() {
    this.disableElement();

    if(!this.validate()) return;

    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.stripeCard,
      billing_details: {
        name: this.$nameOnCard.val()
      },
    }).then(this.onCardFormSubmitComplete)
  }

  onCardFormSubmitComplete(result) {
    const cardToken = result?.paymentMethod?.id;

    if(!cardToken) {
      this.onCardFormSubmitError({ cardErrorMessage: result.error.message });
      this.enableElement();
      return;
    }

    this.$element.dispatch("stripe_card_success", { bubbles: true, detail: { result: result }});
    this.enableElement();
  }

  onCardFormSubmitError(errorMessages) {
    console.log("errorMessages", errorMessages);
    this.setErrorMessages(errorMessages);
    this.$element.dispatch("stripe_card_error", { bubbles: true });
  }

  clearErrorMessages() {
    this.$cardFormGroup.dispatch('clear');
    this.$nameFormGroup.dispatch('clear');
  }

  setErrorMessages(errorMessages = {}) {
    if(errorMessages.hasOwnProperty('cardErrorMessage'))
      this.$cardFormGroup.dispatch('error', { detail: { error: errorMessages.cardErrorMessage }});

    if(errorMessages.hasOwnProperty('nameErrorMessage'))
      this.$nameFormGroup.dispatch('error', { detail: { error: errorMessages.nameErrorMessage }});
  }
}

import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  connect() {
    this.onConnect();

    document.addEventListener(
      "WatchedFieldsBroadcaster",
      this.receiveWatchedFieldsBroadcasterEvent
    );
  }

  disconnect() {
    document.removeEventListener("WatchedFieldsBroadcaster", this.receiveWatchedFieldsBroadcasterEvent);
  }

  receiveWatchedFieldsBroadcasterEvent({ detail: { payload } }) {
    if (payload.model_name == 'SystemEvent') {
      $(payload.templates.system_event.notifications_bell.selector)
        .replaceWith(payload.templates.system_event.notifications_bell.template);

      $(payload.templates.system_event.notifications_bell.selector).lookAtMe();
    }
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  connect() {
    this.onConnect();

    if(this.state.selected_document_id) {
      this.showDocumentById(this.state.selected_document_id);
    }
  }

  onStateDocumentImagePresenceTogglerChanged() {
    if(this.state.document_image_presence_toggler == 'all') {
      return this.$('.documents-carrousel-link').show();
    }

    this.$('.documents-carrousel-link').hide();

    const showNonZeroImages = [
      'with_images',
      'with_embeddable_links_or_images'
    ].includes(this.state.document_image_presence_toggler);

    const showNonZeroEmbeddableLinks = [
      'with_embeddable_links',
      'with_embeddable_links_or_images'
    ].includes(this.state.document_image_presence_toggler);

    if(showNonZeroImages) {
      this.$('.documents-carrousel-link[data-images-count!="0"]').show();
    }

    if(showNonZeroEmbeddableLinks) {
      this.$('.documents-carrousel-link[data-embeddable-links-count!="0"]').show();
    }
  }

  showDocument(e) {
    const { document } = this.getInstanceFromEvent(e, 'document');

    if(!document) return;

    this.showDocumentById(document.id);
  }

  documentCarrouselColumnSelector(documentId) {
    return `.documents-carrousel-column-${documentId}`;
  }

  showDocumentById(documentId) {
    const documentToBeShownSelector = this.documentCarrouselColumnSelector(documentId);

    this.$('.documents-carrousel-link').removeClass('active-secondary-link');
    this.$(`.documents-carrousel-link.documents-carrousel-link-${documentId}`).addClass('active-secondary-link');

    this.$(`.documents-carrousel-column:not(${documentToBeShownSelector})`).hide();
    const $documentToBeShown = $(documentToBeShownSelector);

    $documentToBeShown.fadeIn();

    if($documentToBeShown.find('.document-details').length == 0)
      this.loadDocumentDetails(documentId);
  }

  loadDocumentDetails(documentId) {
    API.ajax({
      url: `/documents/${documentId}/details`,
      method: 'GET',
      complete: (response) => {
        const documentToBeShownSelector = this.documentCarrouselColumnSelector(documentId);
        this.$(documentToBeShownSelector).html(response.responseText);
      }
    });
  }
}

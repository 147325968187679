import DestinationExportOptionsForm from 'destinations/base/export_options_form'

class ContentfulExportOptionsForm extends DestinationExportOptionsForm {
  newDestinationExportTemplateName(usedTemplate, destination, exportOptions, originalExportOptions) {
    const environmentId = exportOptions.destination_meta.environment_id;
    const contentTypeId = exportOptions.destination_meta.content_type_id;
    const contentTypeDisplayName =
          (((destination?.meta?.environments || {})[environmentId] || {}).content_types || {})[contentTypeId]?.name;

    if(!contentTypeDisplayName)
      return super.newDestinationExportTemplateName(usedTemplate, destination, exportOptions);

    if(usedTemplate) {
      const oldEnvironmentId = originalExportOptions.destination_meta.environment_id;
      const oldContentTypeId = originalExportOptions.destination_meta.content_type_id;
      const oldContentTypeDisplayName =
            (((destination?.meta?.environments || {})[oldEnvironmentId] || {}).content_types || {})[oldContentTypeId]?.name;

      return usedTemplate.name.replace(oldContentTypeDisplayName. contentTypeDisplayName);
    }

    return `${destination.default_new_template_name} - ${contentTypeDisplayName}`;
  }
}

export default ContentfulExportOptionsForm;

import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  static modelName = 'Export';

  connect() {
    this.onConnect();
  }

  getReExportData($modal) {
    const reExportData = {};

    $modal.find('input').each((i, input) => {
      const { name, value, isCheckbox, nameParts } = this.getInputMap(input);

      if(nameParts[nameParts.length - 3] == "transformations" &&
         nameParts[nameParts.length - 1] == "selected") {
        if(!reExportData.transformations) reExportData.transformations = {};
        reExportData.transformations[nameParts[nameParts.length - 2]] = true;
      }
    });

    return reExportData;
  }

  reExportNow() {
    API.ajax({
      url: `/exports/${this.state.original_export.id}/reexport`,
      method: 'post',
      data: { reexport: this.state.reexport }
    });
  }

  goToReExportPath() {
    let path = `/exports/new?base_export_id=${this.state.original_export.id}`

    if(this.state.reexport.update_previous_exports.selected) {
      path = `${path}&update_previous`
    }

    window.location = path;
  }
}

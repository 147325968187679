import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [
    'monthlyPlansWrapper',
    'yearlyPlansWrapper',
    'annualMonthlyToggle'
  ];

  connect() {
    this.onConnect();

    this.monthlyPlans = HREF.get('monthly', false);
    this.updateUi();
  }

  showYearlyPlans() {
    this.monthlyPlans = false;
    HREF.replaceState({ monthly: null });
    this.updateUi();
  }

  showMonthlyPlans() {
    this.monthlyPlans = true;
    HREF.replaceState({ monthly: true });
    this.updateUi();
  }

  updateUi() {
    if(this.monthlyPlans) {
      this.$annualMonthlyToggle?.addClass('monthly-selected').removeClass('yearly-selected');
      this.$yearlyPlansWrapper.fadeOut(200, () => {
        this.$monthlyPlansWrapper.fadeIn(200);
      });
      return;
    }

    this.$annualMonthlyToggle?.removeClass('monthly-selected').addClass('yearly-selected');

    if(this.$monthlyPlansWrapper) {
      this.$monthlyPlansWrapper.fadeOut(200, () => {
        this.$yearlyPlansWrapper.fadeIn(200);
      });
    } else {
      this.$yearlyPlansWrapper.show();
    }
  }
}

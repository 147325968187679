import ApplicationController from "controllers/application_controller";
import PriceModel from "billing/price_model";

export default class extends ApplicationController {
  static targets = ['exportsInput', 'exportsPrice', 'exportsMarginal', 'usersInput', 'usersPrice', 'usersMarginal', 'destinationsInput', 'destinationsPrice', 'destinationsMarginal', 'finalPrice'];

  connect() {
    this.onConnect();
    console.log("this.state.exportPriceModel", this.state.exportPriceModel);
    this.exportPriceModel = new PriceModel(this.state.exportPriceModel);
    this.userPriceModel = new PriceModel(this.state.userPriceModel);
    this.destinationPriceModel = new PriceModel(this.state.destinationPriceModel);
    this.updatess();
  }

  knobChanged() {
    this.updatess();
  }

  updatess() {
    this.exports = this.$exportsInput.val();
    this.users = this.$usersInput.val();
    this.destinations = this.$destinationsInput.val();

    this.exportPrice = this.exportPriceModel.c(this.exports);
    this.userPrice = this.userPriceModel.c(this.users);
    this.destinationPrice = this.destinationPriceModel.c(this.destinations);

    this.exportMarginal = this.exportPriceModel.marginalCost(this.exports + 1);
    this.userMarginal = this.userPriceModel.marginalCost(this.users + 1);
    this.destinationMarginal = this.destinationPriceModel.marginalCost(this.destinations + 1);

    this.totalPrice = this.exportPrice + this.userPrice + this.destinationPrice;

    this.$exportsPrice.html("Total: " + this.price(this.exportPrice));
    this.$usersPrice.html("Total: " + this.price(this.userPrice));
    this.$destinationsPrice.html("Total: " + this.price(this.destinationPrice));

    // this.$exportsMarginal.html("Marginal: " + this.price(this.exportMarginal));
    // this.$usersMarginal.html("Marginal: " + this.price(this.userMarginal));
    // this.$destinationsMarginal.html("Marginal: " + this.price(this.destinationMarginal));

    this.$finalPrice.html(this.price(this.totalPrice));
  }

  price(value) {
    return '$'+(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['form',
                    'index',
                    'display',
                    'error',
                    'list',
                    'empty',
                    'token',
                    'dashboardLink'];

  connect() {
    this.onConnect();
    // this.showForm();
  }

  showForm() {
    this.$index.fadeOut(() => {
      this.$form.fadeIn();
    });
  }

  showIndex() {
    this.$form.fadeOut(() => {
      this.$index.fadeIn();
    });
  }

  createToken(e) {
    if(e.keyCode && e.keyCode != 13) return;

    this.$error.fadeOut();
    this.disableElement();

    API.ajax({
      url: '/api_tokens.json',
      method: 'POST',
      data: { api_token: this.state.api_token },
      success: (response) => {
        this.displayApiToken(response.token);
      },
      error: () => {
        this.$error.fadeIn();
      },
      complete: () => {
        return this.enableElement();
      }
    });
  }

  getApiTokenFromEvent(e) {
    const $target = $(e.target);

    const $widgetCard = $target.closest(`[data-api-token]`);
    const apiToken = $widgetCard.data('api-token');

    return { $widgetCard, apiToken };
  }

  confirmDestroyApiToken(e) {
    const { $widgetCard, apiToken } = this.getApiTokenFromEvent(e);
    const detailText = `<div class="text-sm mt-h text-gray-500 italic">This action cannot be undone. All integrations using this API Token will be unable to communicate with Wordable.</div>`;

    dataConfirmModal.confirm({
      text: `<div class="text-red-base text-lg mb-h">You are about to revoke "${apiToken.name}"</div> ${detailText}`,
      onConfirm: () => {
        this.disableNode($widgetCard);
        this.destroyApiToken($widgetCard, apiToken.id);
      },
    });
  }

  destroyApiToken($widgetCard, apiTokenId) {
    this.disableElement();

    API.ajax({
      url: `/api_tokens/${apiTokenId}`,
      method: 'DELETE',
      complete: () => {
        this.enableElement();
        $widgetCard.remove();
        this.ensureListState();
      }
    });
  }

  displayApiToken(token) {
    this.$index.hide();
    this.$form.fadeOut(() => {
      this.$display.fadeIn();
      this.$token.text(token);
      this.generatedToken = token;
    })
  }

  ensureListState() {
    if(this.$('.widget-card').length == 0) {
      this.$list.hide();
      this.$empty.show();
    } else {
      this.$list.show();
      this.$empty.hide();
    }
  }

  copyToken() {
    navigator.clipboard.writeText(this.generatedToken);
    toastr.success('API Token copied!');
    this.$dashboardLink.fadeIn();
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['subscribeForm'];

  connect() {
    this.onConnect();
  }

  submit() {
    this.disableElement();
    this.$subscribeForm.submit();
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  connect() {
    this.onConnect();

    this.$wrapper = this.$element.closest('.popover, .user-hideable-message');
  }

  hide(e) {
    this.disableNode(this.$wrapper);
    const $target = $(e?.target);
    e?.preventDefault();

    API.ajax({
      url: `/users/${this.state.user.id}`,
      method: 'PUT',
      data: JSON.stringify({ user: this.state.user }),
      complete: () => {
        const href = $target.attr('href');

        if(href) window.location = href;

        this.fadeOutAndRemoveNode(this.$wrapper);
      }
    });
  }
}

import ApplicationController from "controllers/application_controller";
import Infl from 'inflector-js';

export default class extends ApplicationController {
  static targets = ['page', 'loading'];

  connect() {
    this.onConnect();
    chrome.tabs.query({ active: true, currentWindow: true }, this.handleTabQuery);

    this.storeTemplates();
    this.syncStyle();
  }

  storeTemplates() {
    this.templates = {};

    $('.chrome-extension-template').each((i, el) => {
      const $el = $(el);

      const templateKey = $el.data('chrome-extension-template');
      if(!templateKey) return;

      const templateHtml = $el.html();
      if(!templateHtml) return;

      this.templates[templateKey] = templateHtml;
      $el.remove();
    });
  }

  syncStyle() {
    chrome.storage.local.get(["currentCss"]).then((result) => {
      let currentCss = result.currentCss || {};

      this.addStyleTag(currentCss.contents);

      API.ajax({
        url: `${Wordable.global.API_HOST}/chrome_extension/style.json`,
        complete: ({ responseJSON }) => {
          this.loadStyle(currentCss, responseJSON);
        }
      });
    });
  }

  loadStyle(currentCss, newCss) {
    if(currentCss.href == newCss.href) return;

    API.ajax({
      url: newCss.href,
      complete: ({ responseText }) => {
        newCss.contents = responseText.replaceAll('url(/packs/', `url(${newCss.base_url}/packs/`);
        this.addStyleTag(newCss.contents);
        chrome.storage.local.set({ currentCss: newCss });
      }
    });
  }

  addStyleTag(styleContents) {
    if(!styleContents) return;

    $('#application-css').remove();
    $('body').append(`<style id="application-css">${styleContents}</style>`);
    $('body').show();
  }

  handleTabQuery(tabs) {
    const currentTab = tabs[0];
    this.currentUrl = currentTab.url;
    this.resolveCurrentPage();
  }

  onStateSearchConnectionIdChanged() {
    this.showCurrentPage();
  }

  ensureServiceWorkerWebSocket() {
    chrome.runtime.sendMessage({
      name: 'ensure_websocket'
    });
  }

  resolveCurrentPage() {
    this.route = Wordable.matchRoute(this.currentUrl);
    this.showCurrentPage();
  }

  showCurrentPage() {
    this.showLoading();

    if(this.route.name == 'google_drive/document') {
      return this.showDocument(this.route.params.originalSourceDocumentId);
    }

    if(this.route.name == 'google_drive/folder') {
      return this.showFolder(this.route.params.originalSourceFolderId);
    }

    if(this.route.name == 'google_drive/my-drive') {
      return this.showMyDrive();
    }

    this.showInvalidPage();
  }

  showInvalidPage() {
    this.showPage();
    this.$page.html(this.templates.invalidPage);
  }

  showMyDrive() {
    this.showPage();
    this.$page.html(this.templates.myDrivePage);
  }

  showDocument(originalSourceDocumentId) {
    API.ajax({
      url: `${Wordable.global.API_HOST}/chrome_extension/document.json?external_document[id]=${originalSourceDocumentId}&${this.searchQueryParam()}`,
      complete: ({ responseJSON }) => {
        this.$page.html(responseJSON.template);
        this.showPage();
      }
    });
  }

  showFolder(originalSourceFolderId) {
    API.ajax({
      url: `${Wordable.global.API_HOST}/chrome_extension/folder.json?external_folder[id]=${originalSourceFolderId}&${this.searchQueryParam()}`,
      complete: ({ responseJSON }) => {
        this.$page.html(responseJSON.template);
        this.showPage();
      }
    });
  }

  showLoading() {
    this.$page.hide();
    this.$loading.show();
  }

  showPage() {
    this.$page.show();
    this.$loading.hide();
    this.enableElement();
  }

  reloadDocument(event) {
    this.disableElement();
    this.showDocument(event.detail.originalSourceDocumentId);
  }

  searchQueryParam() {
    return `search[connection_id]=${this.state.search?.connection_id || ''}`;
  }

  receiveMessage(message) {
    if(message?.detail?.global_event?.name == "WatchedFieldsBroadcaster") {
      this.receiveWatchedFieldsBroadcasterEvent(message);
    }
  }
}

// Append settings sidebar after page load
document.addEventListener("turbolinks:load", () => {
  const html = 

  // Append html to body
  // $("body").append(html);

  // Toggle settings
  $(".toggle-settings").on("click", function(e) {
    e.preventDefault();

    $(".settings").toggleClass("open");
  });

  // Close sidebar on outside click
  $(document).on('click', function (e) {
    if ($(e.target).closest(".settings").length === 0) {
      $(".settings").removeClass("open");
    }
  });
});

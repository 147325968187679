import party from "party-js";

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  connect() {
    this.onConnect();
    document.addEventListener("WatchedFieldsBroadcaster", this.receiveWatchedFieldsBroadcasterEvent);
  }

  disconnect() {
    document.removeEventListener("WatchedFieldsBroadcaster", this.receiveWatchedFieldsBroadcasterEvent);
  }

  showModal() {
    if(this.state.below_four_exports) {
      this.blockWithSubscriptionCta({ variant: 'export' });
    } else {
      this.blockWithSubscriptionCta({ variant: 'exports-left-badge' });
    }

    // Helper to work with confetti
    // this.celebrate(this.$element);
  }

  receiveWatchedFieldsBroadcasterEvent(event) {
    const { global_event, payload } = event.detail;

    const template = payload?.templates?.nav?.exports_left_badge;

    if(!template?.selector) return;

    const $current = $(template.selector);
    const $new = $(template.template);

    $current.after($new);
    $current.remove();

    if(parseInt(payload?.available_exports_count_cache) > parseInt(payload?.available_exports_count_cache_before_save)) {
      this.celebrate($new);
    }

    this.modules();
  }

  celebrate($element) {
    $element.lookAtMe();

    party.confetti($element[0], {
      count: party.variation.range(50, 100),
    });
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['checkbox'];

  connect() {
    this.onConnect();

    this.setSelectedFromInput();
  }

  setSelectedFromInput() {
    this.selected = this.$checkbox.is(':checked');
    this.updateUi();
  }

  hiddenInputChanged() {
    this.setSelectedFromInput();
  }

  toggle() {
    if(this.$element.is('[data-disabled]')) return;

    this.selected = !this.selected;
    this.$checkbox.changeTo(this.selected);
    this.updateUi();
  }

  updateUi() {
    if(this.selected) {
      this.$element.addClass('switch-selected');
    } else {
      this.$element.removeClass('switch-selected');
    }
  }
}

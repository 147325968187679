import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [
    'featuredImageIdField',
    'metaDescriptionField',
    'metaDescriptionFieldWrapper'
  ]

  connect() {
    this.onConnect();

    this.styleMetaDescriptionField();
  }

  styleMetaDescriptionField() {
    // const $fromGroup = this.$metaDescriptionFieldWrapper.find('.form-group');
    const wrapperHeight = this.$metaDescriptionFieldWrapper.innerHeight();
    let availableHeight = wrapperHeight - 20;

    this.$metaDescriptionFieldWrapper.find('> *').each((i, e) => {
      const $e = $(e);

      if(!$e.is('textarea')) {
        availableHeight -= $e.outerHeight(true);
      }
    });

    this.$metaDescriptionField.css({ height: availableHeight + 1 });
  }

  setDocumentImageDocumentFeaturedImageId(stateChange) {
    this.$featuredImageIdField.changeTo(stateChange['document_image[document][featured_image_id]'])
  }
}

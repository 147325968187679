// OLD, related to the slider
import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [
    "slider",
    "purchaseMessage",
    "priceMessage",
    "pricePerExportMessage",
    "discountMessage"
  ];

  connect() {
    this.onConnect();

    this.plansByInterval = {
      year: this.getPlansByInterval('year'),
      month: this.getPlansByInterval('month')
    };

    this.selectedInterval = this.state.selected_plan?.interval || 'year';
    this.setInitialBillingPlanIndex();

    this.setupUi();
  }

  setInitialBillingPlanIndex() {
    const selectedPlanId = parseInt(this.state.selected_plan?.id || this.state.current_plan?.id);
    let selectedPlanIndex = 0;

    if(selectedPlanId == 0 || isNaN(selectedPlanId)) {
      selectedPlanIndex = 0;
    } else if(this.state.on_legacy_plan) {
      selectedPlanIndex = this.plansByInterval.year.findIndex((plan) => {
        return plan.amount > this.state.current_plan.amount;
      });
    } else {
      selectedPlanIndex = this.plansByInterval.year.findIndex((plan) => {
        return plan.id == selectedPlanId;
      });

      if(selectedPlanIndex == -1) {
        selectedPlanIndex = this.plansByInterval.month.findIndex((plan) => {
          return plan.id == selectedPlanId;
        })
      };
    }

    if(selectedPlanIndex == -1) {
      this.setSelectedBillingPlanIndex(0);
    } else {
      this.setSelectedBillingPlanIndex(selectedPlanIndex);
    }
  }

  getPlansByInterval(interval) {
    return this.state.plans.filter((plan) => {
      return plan.interval == interval || plan.amount == 0;
    }).sort((pa, pb) => {
      return pa.amount - pb.amount;
    });
  }

  setSelectedBillingPlanIndex(index) {
    this.selectedBillingPlanIndex = index;
    this.setSelectedBillingPlan();
  }

  onStateYearlyChanged() {
    if(this.state.yearly) {
      this.selectedInterval = 'year';
    } else {
      this.selectedInterval = 'month';
    }

    this.setSelectedBillingPlan();
  }

  setSelectedBillingPlan() {
    this.selectedBillingPlan = this.plansByInterval[this.selectedInterval][this.selectedBillingPlanIndex];

    if (history.pushState && this.selectedBillingPlan && window.URLSearchParams) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set("plan_id", this.selectedBillingPlan.id);

      var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
      window.history.replaceState({ path: newUrl }, '', newUrl);
    }

    this.updateUi();
  }

  setupUi() {
    this.$slider.slider({
      value: this.selectedBillingPlanIndex,
      range: "min",
      min: 0,
      max: this.plansByInterval[this.selectedInterval].length - 1,
      step: 1,
      change: (event, ui) => {
        this.setSelectedBillingPlanIndex(ui.value);
      },
      slide: (event, ui) => {
        this.setSelectedBillingPlanIndex(ui.value);
      }
    });
  }

  sliderStepClicked(e) {
    const index = parseInt($(e.target).data('index'));
    this.$slider.slider("value", index);
    // this.setSelectedBillingPlanIndex(index);
  }

  updateUi() {
    const isFree = this.selectedBillingPlan.amount == 0;

    this.$('.monthly-exports').html(this.selectedBillingPlan.monthly_exports);

    if(isFree) {
      this.$('.period-price').hide();
      this.$('.price-per-export').hide();
      this.$('.period-label').html('');
      this.$('.discounted-period-price').html('Free');
      this.$('.discounted-price-per-export').html('&nbsp;');
      this.$('#plan-yearly-switch').hide();
    } else {
      const periodPriceSuffix = this.state.yearly ? '/yr' : '/mo'

      if(this.selectedBillingPlan.discounted) {
        this.$('.period-price').html(this.price(this.selectedBillingPlan.price)+periodPriceSuffix);
        this.$('.price-per-export').html(this.price(this.selectedBillingPlan.price_per_export)+'/export');
        this.$('.period-price').show();
        this.$('.price-per-export').show();
      } else {
        this.$('.period-price').hide();
        this.$('.price-per-export').hide();
      }

      this.$('.discounted-period-price').html(this.price(this.selectedBillingPlan.discounted_price)+periodPriceSuffix);
      this.$('.discounted-price-per-export').html(this.price(this.selectedBillingPlan.discounted_price_per_export)+'/export');
      this.$('#plan-yearly-switch').show();
    }

    const planWrapperSelector = `#billing-plan-card-${this.selectedBillingPlan.id}`;
    this.$('.billing-plan-card').hide();
    this.$(planWrapperSelector).show();
  }

  price(value) {
    return '$'+(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
}

import 'images';
// import 'fonts';
import 'stylesheets/application';

// These polyfills ensure that modern JS features
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// 3rd libs
// import '@fortawesome/fontawesome-free';
import '@fortawesome/fontawesome-pro';

import "bootstrap";
import 'bootstrap-select';

// Modules
import "modules/theme";
import "modules/jquery-plugins";
import "modules/google_analytics";
import "modules/sentry";
import "modules/settings";
import "modules/site";
import "modules/typeform";
import API from "api/index";
import HREF from "href/index";
import installAll from "modules/install_all";

window.HREF = HREF;
window.API = API;
window.Rails = require("@rails/ujs")
window.installAll = installAll;

require("turbolinks").start();
require("@rails/activestorage").start();
// import "@hotwired/turbo-rails"
require("channels");

require("trix");
require("@rails/actiontext");

import "controllers"
Rails.start();

// this needs to be after Rails.start()
// https://github.com/ifad/data-confirm-modal
require('data-confirm-modal');

dataConfirmModal.setDefaults({
  title: 'Please Confirm',
  commit: 'Continue',
  cancel: "I've changed my mind",
  commitClass: 'btn-danger text-lg',
  cancelClass: 'btn-basic text-lg',
  verifyClass: 'form-control',
  modalClass: 'text-lg confirm-modal'
});

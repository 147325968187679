import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [];

  connect() {
    this.onConnect();

    this.cancelVariants = {
      refund: {
        submit: 'Refund my payment',
        confirm: this.cancelSubscription
      },
      cancel: {
        submit: 'Cancel my subscription',
        confirm: this.cancelSubscription
      },
      delete: {
        submit: 'Delete Account',
        confirm: this.cancelTeamAccount
      }
    }
  }

  showCancelModal(e) {
    this.cancelVariant = this.cancelVariants[e.params.variant];
    if(!this.cancelVariant) return;

    if(this.state.skip_barecancel) {
      this.bareCancelConfirm();
    } else {
      $('#barecancel-trigger').trigger('click');
      this.setupCancellationBmModal();
    }
  }

  bareCancelConfirm() {
    if(!this.cancelVariant) return;
    this.cancelVariant.confirm();
  }

  cancelTeamAccount() {
    API.ajax({
      url: this.state.paths.cancel_account,
      method: 'DELETE',
      complete: () => {
        window.location = "/";
      }
    });
  }

  cancelSubscription() {
    API.ajax({
      url: this.state.paths.cancel_subscription,
      method: 'DELETE',
      complete: () => {
        $('#default-modal').on('hidden.bs.modal', () => {
          window.location = "/";
        });
      }
    });
  }

  setupCancellationBmModal() {
    const $bmModal = $('.bm-cancel form');

    if($bmModal.length == 0) {
      setTimeout(this.setupCancellationBmModal, 100);
      return;
    }

    if(this.cancelVariant.submit) {
      $bmModal.find('.bm-action-submit').text(this.cancelVariant.submit);
    }
  }
}

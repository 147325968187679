import consumer from "./consumer"

window.cable = consumer.subscriptions.create("NotificationChannel", {
  connected() {},

  disconnected() {},

  received(data) {
    try {
      if (data.global_event) {
        const event = new CustomEvent(data.global_event.name, { detail: data });
        document.dispatchEvent(event);
      }

      if (data.message) {
        // TODO: Remove this shit
        window.toastr.success(data.message);
      }
    } catch {}
  }
});

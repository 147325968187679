import WidgetController from "controllers/widget_controller";
import DocumentsConcern from "controllers/concerns/documents";

export default class extends WidgetController {
  static targets = [
    'container',
    'loading',
    'empty',
    'pageInput',
    'bulkActions',
    'bulkSelectionToggler'
  ];

  static concerns = [DocumentsConcern];

  static modelName = 'Document';
  static deleteDetailText = "This action cannot be undone."

  connect() {
    this.onConnect();
  }

  selectBulkExport() {
    this.$element.dispatch('selectBulkExport', {
      detail: {
        documents: this.selectedWidgetCards.filter((widgetCard) => {
          return widgetCard.import_state == 'completed';
        })
      }
    });

    setTimeout(this.clearWidgetCardsSelection, 500);
  }

  handleSearchSuccess(response) {
    super.handleSearchSuccess(response);

    if(this.state.onboarder?.step == 3) {
      this.lookAtExportButtons();
    }
  }

  lookAtExportButtons() {
    this.$('.document-widget-card-select-to-export-button').keepLookingAtMe();
  }

  reimport(e) {
    const $target = $(e.target);
    const $widgetCard = $target.closest(`[data-external-document]`);

    if($widgetCard.length == 0) return;

    const importableAttributes = $widgetCard.data(`external-document`);
    this.disableNode($widgetCard);

    $.ajax({
      method: 'POST',
      url: `/imports/import_document`,
      data: { [`external_document`]: importableAttributes }
    });
  }

  onStateSearchOriginalSourceConnectionIdChanged() {
    this.clearSearchTimeoutAndSearch();
  }

  onStateSearchOrderChanged() {
    this.clearSearchTimeoutAndSearch();
  }

  onStateSearchImportStateChanged() {
    this.clearSearchTimeoutAndSearch();
  }

  onStateSearchTagsChanged() {
    this.timeoutedSearch();
  }

  onStateSearchQueryStringChanged() {
    this.timeoutedSearch();
  }

  onStateSearchPerPageChanged() {
    this.clearSearchTimeoutAndSearch();
  }

  onStateSearchPageChanged() {
    this.clearSearchTimeoutAndSearch();
  }

  onStateSearchStatusChanged() {
    this.clearSearchTimeoutAndSearch();
  }

  receiveWatchedFieldsBroadcasterEvent({ detail: { payload } }) {
    if (payload.model_name == 'Document') {
      if(payload.changed_fields.import_state) {
        $(payload.templates.document.widget_card.selector).remove();
        let $target = $(payload.templates.document.widget_card.template);
        this.$container.find('.widget-body-container-list:first').prepend($target);
        this.$container.show();
        this.$loading.hide();
        this.$empty.hide();
        $target.lookAtMe();
        this.modules();
      } else {
        $(payload.templates.document.widget_card.selector).replaceWith(payload.templates.document.widget_card.template);
      }
    }
  }
}

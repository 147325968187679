import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['label', 'options', 'hiddenField', 'queryStringInput'];

  connect() {
    this.onConnect();
    this.mode = this.$element.data('mode') || 'single';
    this.multiJoiner = this.$element.data('multi_joiner') || '';
    this.placeholder = this.$element.data('placeholder')

    // Hard coded for now.
    this.emptyValue = '';
    this.selectedOptionClass = 'select-dropdown-selected-option';

    this.$hiddenField.on('change', this.hiddenFieldChanged)
    this.setLabel();

    if(this.$options.find('.dropdown-item').length >= 5) {
      this.$queryStringInput.show();
    }
  }

  hiddenFieldChanged() {
    this.setLabel();
  }

  selectOption(e) {
    const $option = $(e.target).closest('[data-value]');
    let newValue = $option.data('value');

    if(this.mode == 'multiple') {
      e.stopPropagation(); // Keep the thing open

      if(newValue == this.emptyValue) {
        newValue = JSON.stringify(['']);
      } else {
        const currentValue = this.value().filter((value) => {
          return value != this.emptyValue;
        });

        const newValueIndex = currentValue.indexOf(newValue);

        if(newValueIndex == -1) {
          newValue = JSON.stringify([...new Set([...currentValue, newValue])]);
        } else {
          currentValue.splice(newValueIndex, 1);

          if(currentValue.length == 0) {
            newValue = JSON.stringify(['']);
          } else {
            newValue = JSON.stringify(currentValue);
          }
        }
      }
    }

    this.$hiddenField.changeTo(newValue);
  }

  value() {
    const value = this.$hiddenField.val();

    if(this.mode == 'single') {
      return value;
    }

    try {
      const parsedValue = JSON.parse(value);
      if(Array.isArray(parsedValue)) return parsedValue;
      return [parsedValue];
    } catch {
      return [''];
    }
  }

  selectedOptions() {
    return [this.value()].flat().map((selectedValue) => {
      return this.$options.find(`[data-value="${selectedValue}"]`)
    });
  }

  setLabel() {
    const selectedOptions = this.selectedOptions();

    const labels = selectedOptions.map((selectedOption) => {
      return selectedOption.html();
    });

    this.$options.find('.dropdown-item').removeClass(this.selectedOptionClass);

    selectedOptions.forEach(($element) => {
      $element.addClass(this.selectedOptionClass);
    })

    const labelsCount = labels.length;

    if(labelsCount == 1) {
      const value = this.value();

      if(this.placeholder &&
         ((this.mode == 'single' && value == this.emptyValue) ||
          (this.mode == 'multiple' && value[0] == this.emptyValue))) {
        this.$label.html(`<div class='select-dropdown-placeholder'>${this.placeholder}</div>`);
      } else {
        this.$label.html(labels[0]);
      }
    } else if(labelsCount > 1) {
      const labelHtml = labels.join(this.multiJoiner);
      this.$label.html(labelHtml);
    }
  }

  onStateSearchQueryStringChanged() {
    const dropdownItems = this.$options.find('.dropdown-item');
    const queryString = this.state.search.query_string.toLowerCase();

    if(!this.state.search.query_string || this.state.search.query_string.length == 0) {
      dropdownItems.show();
    }

    dropdownItems.each((i, item) => {
      const $item = $(item);
      const text = $item.text();

      if(text.toLowerCase().indexOf(queryString) == -1) {
        $item.hide();
      } else {
        $item.show();
      }
    })
  }
}

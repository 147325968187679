import ApplicationController from "controllers/application_controller";
export default class extends ApplicationController {
  static targets = [
    'errorBadge'
  ];

  connect() {
    this.onConnect();
    this.export = this.$element.data('export');
  }

  ensureErrorTooltip(e) {
    if(this.ensuredErrorTooltip) return;
    this.ensuredErrorTooltip = true;

    API.ajax({
      url: `/exports/${this.export.id}/error_badge.json`,
      complete: ({ responseJSON }) => {
        this.$errorBadge.popover('hide');
        const $newErrorBadge = $(responseJSON.tooltip);
        this.$errorBadge.replaceWith($newErrorBadge);
        this.$errorBadge = $newErrorBadge;
        installAll();
        this.$errorBadge.popover('show');
      }
    });
  }
}

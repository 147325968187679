import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['goButton', 'error', 'instructions'];

  connect() {
    this.onConnect();

    const searchParams = new URLSearchParams(window.location.search);

    this.step = searchParams.get('step');
    if(this.step != 'activation') this.step = 'installation';
    this.updateUi();
  }

  onStateWebsiteHostChanged() {
    let websiteHost = this.state.websiteHost;

    try {
      websiteHost = websiteHost.trim().replaceAll(/https?:?\/?\/?/g, '');

      const installPageUrl = new URL('https://'+websiteHost);
      const pluginSettingsPageUrl = new URL('https://'+websiteHost);

      const currentPathname = installPageUrl.pathname.split('wp-admin')[0];

      installPageUrl.pathname = `${currentPathname}/wp-admin/plugin-install.php`.replaceAll('//', '/');
      installPageUrl.search = 's=Wordable&tab=search&type=term';

      pluginSettingsPageUrl.pathname = `${currentPathname}/wp-admin/admin.php`.replaceAll('//', '/');
      pluginSettingsPageUrl.search = 'page=wordable-plugin';

      this.state.targets = {
        install_page: installPageUrl.toString(),
        plugin_settings_page: pluginSettingsPageUrl.toString()
      };

      this.$('input[name="state[websiteHost]"]').each((i, input) => {
        $(input).val(websiteHost);
      });

      this.state.error = false
    }
    catch(x) {
      this.state.targets = null;
      this.state.error = true;
    }

    this.updateUi();
  }

  updateUi() {
    if(this.state.error) {
      this.$goButton.hide();
      this.$error.show();
    } else if(this.state.targets) {
      this.$goButton.show();
      this.$error.hide();
    }

    if(this.step == 'installation') {
      this.$('.installation-instructions-visible').fadeIn();
      this.$('.activation-instructions-visible').hide();
    } else if(this.step == 'activation') {
      this.$('.installation-instructions-visible').hide();
      this.$('.activation-instructions-visible').fadeIn();
    }
  }

  go(e) {
    const $target = this.$(e.target).closest('[data-go-target]')
    const target = $target.data('go-target');

    if(!target) return;

    const url = this.state.targets[target];

    if(!url) return;

    window.open(url);
    this.showActivationInstructions();
  }

  showInstallationInstructions() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('step');

    var searchPart = searchParams.toString();

    if(searchPart.length > 0) {
      searchPart = `?${searchPart}`;
    }

    var newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + searchPart;
    window.history.replaceState({ path: newUrl }, '', newUrl);

    this.step = 'installation';
    this.updateUi();
  }

  showActivationInstructions() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('step', 'activation');

    var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
    window.history.replaceState({ path: newUrl }, '', newUrl);

    this.step = 'activation';
    this.updateUi();
  }
}

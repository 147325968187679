const ImportFolderConcern = {
  confirmImportFolder(e) {
    dataConfirmModal.confirm({
      title: 'Are you sure?',
      text: `This will import ALL folder documents, which might make Wordable a little messy.`,
      onConfirm: () => {
        this.importFolder(e);
      },
    });
  }
}

export default ImportFolderConcern;

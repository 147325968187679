import ApplicationController from "controllers/application_controller";
import ImportFolderConcern from "controllers/concerns/import_folder";
import GoogleDrivePicker from "connections/google_drive/picker";

export default class extends ApplicationController {
  static targets = ['topRight', 'query', 'container', 'loading']
  static concerns = [ImportFolderConcern];

  connect() {
    this.onConnect();
    this.setContainerInitiallyLoading();
    this.search();
    document.addEventListener("WatchedFieldsBroadcaster", this.receiveWatchedFieldsBroadcasterEvent);
    this.sourcePickers = {};

    // setTimeout(() => {
    //   $('[data-controller="imports-widget"]').find('span.ml-2.italic.text-sm.flex-1').click();
    // }, 500)
  }

  setContainerInitiallyLoading() {
    this.$container.hide();
    this.$container.html('');
    this.$loading.fadeIn();
  }

  getQueryStringInput() {
    return $('#imports-widget-query-string-input');
  }

  showSourcePicker(e) {
    const pickerConfig = $(e.target).closest('[data-picker-config]').data('picker-config');

    if(!this.sourcePickers[pickerConfig.connection_id]) {
      let klass = null;

      if(pickerConfig.type == 'GoogleDriveConnection') {
        klass = GoogleDrivePicker;
      }

      this.sourcePickers[pickerConfig.connection_id] = new klass({ ...pickerConfig, onPick: this.onSourcePick });
    }

    this.sourcePickers[pickerConfig.connection_id]?.show();
  }

  onSourcePick(doc) {
    this.innerImport('document', doc, 'webapp_picker');
  }

  search() {
    this.disableContainer();

    this.queryStringInputStateBeforeSearch = this.getQueryStringInput().getState();

    if(this.state.search.query_string == '#SENTRY_TEST') {
      throw new Error('Sentry Test is Tested');
    }

    $.ajax({
      url: `/imports/search`,
      data: this.state.search,
      success: this.handleSearchSuccess,
      complete: this.handleSearchComplete
    });
  }

  import(e) {
    const $target = $(e.target);
    const $widgetCard = $target.closest(`[data-importable-type]`);

    if($widgetCard.length == 0) return;

    const importableType = $widgetCard.data("importable-type");
    const importableAttributes = $widgetCard.data(`external-${importableType}`);

    this.disableNode($widgetCard);
    this.innerImport(importableType, importableAttributes);
  }

  importFolder(e) {
    this.import(e);
  }

  innerImport(importableType, importableAttributes, trigger = 'webapp') {
    this.stopLookingAtImportButtons();

    $.ajax({
      method: 'POST',
      url: `/imports/import_${importableType}`,
      data: { [`external_${importableType}`]: importableAttributes, trigger: trigger }
    });
  }

  receiveWatchedFieldsBroadcasterEvent(event) {
    const { global_event, payload } = event.detail;

    if (payload.model_name == 'Document' && payload.changed_fields.import_state) {
      this.fadeOutAndRemoveNode(
        this.$element
          .find(`[data-template-id="external-document-widget-card-${payload.external_id}"]`)
      );
    } else if (payload.model_name == 'ExternalFolder') {
      this.fadeOutAndRemoveNode(
        this.$element
          .find(`[data-template-id="external-folder-widget-card-${payload.id}"]`)
      );
    }
  }

  disableContainer() {
    this.disableNode(this.$container);
  }

  enableContainer() {
    this.enableNode(this.$container);
  }

  onStateSearchConnectionIdChanged() {
    if(this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.search();
  }

  onStateSearchEntityChanged() {
    if(this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.search();
  }

  onStateSearchQueryStringChanged() {
    if(this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(this.search, 500);
  }

  createSource(e) {
    const $target = $(e.target);
    const $widgetCard = $target.closest(`[data-external-folder]`);

    if($widgetCard.length == 0) return;

    const externalFolder = $widgetCard.data(`external-folder`);

    this.disableNode($widgetCard);

    $.ajax({
      method: 'POST',
      url: `/sources`,
      data: { external_folder: externalFolder },
      complete: this.handleExternalFolderUpdated
    });
  }

  // Saved: Actually Watch Folders
  confirmDestroySource(e) {
    const $target = $(e.target);
    const $widgetCard = $target.closest(`[data-external-folder]`);

    if($widgetCard.length == 0) return;

    const externalFolder = $widgetCard.data(`external-folder`);

    const detailText =
          `<div class="text-sm mt-h text-gray-500 italic">You can add it again anytime from the dashboard</div>`;

    dataConfirmModal.confirm({
      title: 'Remove from sources?',
      text: `${detailText}`,
      // commit: `Stop Watching ${source.name}`,
      // cancel: "Keep Watching",
      onConfirm: () => {
        this.disableNode($widgetCard);
        this.destroySource(externalFolder);
      },
    });
  }

  // Saved: Actually Watch Folders
  destroySource(externalFolder) {
    $.ajax({
      method: 'DELETE',
      url: `/sources/${externalFolder.source_id}.json`,
      complete: this.handleExternalFolderUpdated
    });
  }

  handleExternalFolderUpdated({ responseJSON: { templates: { external_folder: { widget_card }}}}) {
    const currentWidgetCard = $(widget_card.selector);

    if(currentWidgetCard.length > 0) {
      currentWidgetCard.after(widget_card.template);
      currentWidgetCard.remove();
      this.modules();
    }
  }

  lookAtImportButtons() {
    this.$('.import-widget-card-import-button').keepLookingAtMe();
  }

  stopLookingAtImportButtons() {
    this.$('.import-widget-card-import-button').stopLookingAtMe();
  }

  syncSources() {
    const $syncSourcesButton = $('#imports-search-folders-results-sync-sources-button');
    this.disableNode($syncSourcesButton);

    API.ajax({
      method: 'POST',
      url: `/connections/${this.state.search.connection_id}/sync_sources.json`,
      complete: () => {
        $syncSourcesButton.remove();
      }
    });
  }

  handleSearchSuccess(response) {
    this.$loading.hide();
    this.$container.show();
    this.$container.html(response.templates.results);
    this.$topRight.html(response.templates.top_right);
    this.$query.html(response.templates.query);
    this.modules();
    this.setInitialState();

    if(this.state.onboarder?.step == 2) {
      this.lookAtImportButtons();
    }
  }

  handleSearchComplete() {
    this.enableContainer();

    if(this.queryStringInputStateBeforeSearch?.isFocus) {
      const $queryStringInput = this.getQueryStringInput();

      if(this.queryStringInputStateBeforeSearch.selectionEnd) {
        $queryStringInput.setState(this.queryStringInputStateBeforeSearch);
      } else {
        const val = $queryStringInput.val();
        $queryStringInput.focus();
        $queryStringInput.val('');
        $queryStringInput.val(val);
      }
    }
  }
}

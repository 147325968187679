import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['timer'];

  connect() {
    this.onConnect();
    this.validUntil = this.state.prizes.valid_until ? new Date(this.state.prizes.valid_until) : null;

    // Dev helper
    // this.validUntil = new Date(); this.validUntil.setHours(this.validUntil.getHours() - 1);

    this.setupTimer();
  }

  disconnect() {
    this.teardownTimer();
  }

  setupTimer() {
    this.teardownTimer();
    this.timerInterval = setInterval(this.timerTick, 1000);
  }

  teardownTimer() {
    if(this.timerInterval) clearInterval(this.timerInterval);
  }

  timerTick() {
    if(!this.validUntil) return this.teardownTimer();

    const secondsSinceSignup = parseInt((new Date() - this.signupTime) / 1000);
    let secondsLeft = parseInt((this.validUntil - new Date()) / 1000);

    if(secondsLeft <= 0) {
      this.teardownTimer();
      return;
    }

    this.$('.nav-onboarding-timer').show();
    const $activeTimer = this.$('.nav-onboarding-step-active .nav-onboarding-timer');

    const hoursLeft = parseInt(secondsLeft / 3600);
    secondsLeft = secondsLeft - hoursLeft * 3600;

    const minutesLeft = parseInt(secondsLeft / 60);
    secondsLeft = secondsLeft - minutesLeft * 60;

    $activeTimer.find('.nav-onboarding-clock-hours').text(String(hoursLeft).padStart(2, '0')+'h');
    $activeTimer.find('.nav-onboarding-clock-minutes').text(String(minutesLeft).padStart(2, '0')+'m');
    $activeTimer.find('.nav-onboarding-clock-seconds').text(String(secondsLeft).padStart(2, '0')+'s');
    $activeTimer.find('.nav-onboarding-clock').show();
  }
}

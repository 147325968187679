import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [
    'cardFields',
    'emailInput',
    'signupPaymentMethodIdInput',
    'userFirstNameInput',
    'userLastNameInput',
    'signupForm'
  ];

  connect() {
    this.onConnect();
  }

  // TODO: DRY with card/inline_edit_controller
  submit(e) {
    e.preventDefault();
    this.disableElement();

    this.validateEmail();
    this.validateCard();
  }

  validateCard() {
    this.validCard = false;
    this.validatingCard = true;
    this.$cardFields.dispatch("submit");
  }

  validateEmail() {
    this.clearFieldError(this.$emailInput);
    this.validEmail = false;
    this.validatingEmail = true;

    API.ajax({
      url: `/users/validate.json?user[email]=${this.$emailInput.val()}`,
      method: 'GET',
      complete: ({ responseJSON }) => {
        if(responseJSON.errors.email) {
          this.validEmail = false;
          this.setFieldError(this.$emailInput, responseJSON.errors.email[0]);
        } else {
          this.validEmail = true;
        }

        this.validatingEmail = false;
        this.checkSubmit();
      }
    });
  }

  onStripeCardError(e) {
    this.validatingCard = false;
    this.validCard = false;
    this.checkSubmit();
  }

  onStripeCardSuccess(e) {
    const nameParts = e.detail.result.paymentMethod.billing_details.name.trim().split(' ');

    this.$signupPaymentMethodIdInput.val(e.detail.result.paymentMethod.id);
    this.$userFirstNameInput.val(nameParts[0]);
    this.$userLastNameInput.val(nameParts[nameParts.length - 1]);

    this.validatingCard = false;
    this.validCard = true;

    this.checkSubmit();
  }

  checkSubmit() {
    if(!this.validCard || !this.validEmail) {
      if(!this.validatingEmail && !this.validatingCard) this.enableElement();
      return;
    }

    this.$signupForm.submit();
  }
}

function handleGa4TrackEvent(e) {
  const target = $(e.target);
  const params = target.data('ga4');

  const tracked = $(e.target).data('ga4-tracked');
  if(tracked) return;

  $(e.target).data('ga4-tracked', true);
  ga4TrackEvent(params);
}

function ga4TrackEvent(params, event = 'click') {
  if(!gtag || !params) return;

  gtag("event", event, params);
}

function ga4TrackUser(params) {
  if(!gtag || !params) return;

  gtag('set', 'user_properties', params);
}

window.handleGa4TrackEvent = handleGa4TrackEvent;
window.ga4TrackEvent = ga4TrackEvent;

$(document).on("turbolinks:load", () => {
  $(document).off('click', '[data-ga4]', handleGa4TrackEvent);
  $(document).on('click', '[data-ga4]', handleGa4TrackEvent);

  let firstVisitTrack = Wordable?.global?.flash?.first_visit_track;
  if(firstVisitTrack) ga4TrackEvent(firstVisitTrack, firstVisitTrack.event || 'click');

  if(Wordable.global.ab_test) ga4TrackUser(Wordable.global.ab_test);
});

import { Controller } from "@hotwired/stimulus"
import Infl from 'inflector-js';

class ApplicationController extends Controller {
  constructor(...args) {
    super(...args);

    let currentClass = null;

    // Isn't javascript a piece of shit? :)
    // Auto-binding every method and extending with concerns
    while(currentClass != ApplicationController) {
      currentClass = currentClass ? Object.getPrototypeOf(currentClass) : Object.getPrototypeOf(this.constructor);

      Object.getOwnPropertyNames(currentClass.prototype).forEach((propertyName) => {
        if(typeof this[propertyName] == 'function' && propertyName != 'constructor') {
          this[propertyName] = this[propertyName].bind(this);
        }
      });

      if (currentClass.concerns) {
        currentClass.concerns.forEach((concern) => {
          Object.getOwnPropertyNames(concern).forEach((propertyName) => {
            if(!this[propertyName] && typeof concern[propertyName] == 'function') {
              this[propertyName] = concern[propertyName].bind(this);
            }
          });
        })
      }
    }

    this.setupNames();
  }

  setupNames() {
    if(!this.modelName) {
      if(!this.constructor.modelName) return;

      this.modelName = this.constructor.modelName;
    }

    if(this.modelName) {
      if(!this.singularUnderscoreName) this.singularUnderscoreName = Infl.underscore(this.modelName);
      if(!this.pluralUnderscoreName) this.pluralUnderscoreName = Infl.pluralize(this.singularUnderscoreName);
    }
  }

  onConnect({ jqueryfyTargets } = { jqueryfyTargets: true }) {
    if(this.onConnected) return;

    this.$element = $(this.element);
    this.setInitialState();
    this.onConnected = true;

    if(jqueryfyTargets) {
      this.jqueryfyTargets();
    }
  }

  serializeInputsToMember($node, memberName = "state") {
    $node.find(`[name^="${memberName}"]`).each((index, input) => {
      this.setFromInput({ input, memberName });
    });
  }

  blockWithSubscriptionCta(eventOrOptions) {
    let variant = 'generic';

    if(eventOrOptions?.target) {
      variant = $(eventOrOptions.target).closest('[data-subscription-cta-variant]').data('subscription-cta-variant');
    } else if(eventOrOptions?.variant) {
      variant = eventOrOptions?.variant;
    }

    let subscriptionCta = Wordable.global.subscription_ctas[variant] || Wordable.global.subscription_ctas.generic;

    const upgradePlanModal = $('#subscription-cta-modal');

    upgradePlanModal.addClass(subscriptionCta.modal_class);
    upgradePlanModal.find('.subscription-cta-title').html(subscriptionCta.title);

    let finalDescription = subscriptionCta.description;
    let completedExportCount = Wordable.global?.current_team?.completed_exports_count || 0;

    if(completedExportCount <= 1) {
      finalDescription = finalDescription.replace('$ctaTimeSavedPlaceholder', '1 hour');
    } else {
      finalDescription = finalDescription.replace('$ctaTimeSavedPlaceholder', `${completedExportCount} hours`);
    }

    upgradePlanModal.find('.subscription-cta-description').html(finalDescription);

    if(subscriptionCta.detail) {
      upgradePlanModal.find('.subscription-cta-detail').html(subscriptionCta.detail).show();
    } else {
      upgradePlanModal.find('.subscription-cta-detail').hide();
    }

    upgradePlanModal.modal();
  }

  jqueryfyTargets() {
    this.constructor.targets.forEach((targetName) => {
      const targetProperty = `${targetName}Targets`;
      const checkerProperty = `has${Infl.camelize(targetName)}Target`;
      const jqueryfied = `$${targetName}`;

      if(this[checkerProperty]) {
        this[jqueryfied] = $(this[targetProperty]);
      } else {
        // console.warn(`no ${jqueryfied}`);
      }
    })
  }

  changeFieldTo(fieldName, value) {
    const $field = $(`[name="${fieldName}"]`);

    if($field.length > 0) {
      $field.changeTo(value);
    }
  }

  $(selector) {
    return this.$element.find(selector);
  }

  setInitialState() {
    // TODO: We're including state from children with this :(
    this.state = this.$element.data('state');

    this.$element.find('[name^=state]').each((index, input) => {
      this.setFromInput({ input });
    })
  }

  enableNode($node) {
    const $disableable = $node.find('.spinner-disableable');
    const $spinner = $node.find('.widget-spinner');

    if($disableable.length > 0 && $spinner.length > 0) {
      $disableable.css('opacity', 1);
      $spinner.hide();
    } else {
      $node?.css('opacity', 1);
    }
  }

  disableNode($node) {
    const $disableable = $node.find('.spinner-disableable');
    const $spinner = $node.find('.widget-spinner');

    if($disableable.length > 0 && $spinner.length > 0) {
      $disableable.css('opacity', 0.3);
      $spinner.show();
    } else {
      $node?.css('opacity', 0.3);
    }
  }

  fadeOutAndRemoveNode($node, onComplete) {
    $node?.fadeOutAndRemove(onComplete);
  }

  enableElement() {
    this.enableNode(this.$element);
  }

  disableElement() {
    this.disableNode(this.$element);
  }

  fadeOutAndRemoveElement(onComplete) {
    this.fadeOutAndRemoveNode(this.$element, onComplete);
  }

  getInstanceFromElement($element, singularUnderscoreName = this.singularUnderscoreName) {
    const $widgetCard = $element.closest(`[data-${singularUnderscoreName}]`);
    const instance = $widgetCard.data(singularUnderscoreName);

    return { $widgetCard, [singularUnderscoreName]: instance };
  }

  getInstanceFromEvent(e, singularUnderscoreName = this.singularUnderscoreName) {
    if(!e?.target) return {};

    return this.getInstanceFromElement($(e.target), singularUnderscoreName);
  }

  inputChanged({ target }) {
    const name = target.attributes.name.value;

    let onChangedCallback = this.setFromInput({ input: target });

    if (!onChangedCallback) {
      return;
    }

    onChangedCallback({ name, target });
  }

  getInputMap(input) {
    const name = input.attributes.name.value;
    const value = input.value;
    const isCheckbox = input.attributes.type?.value == 'checkbox';

    let nameParts = name.split('[').map((name_part) => {
      return name_part.replace(']', '');
    });

    return { name, value, isCheckbox, nameParts };
  }

  inputNameParts(inputName) {
    return inputName.split('[').map((name_part) => {
      return name_part.replace(']', '');
    });
  }

  setFromInput({ input, memberName = 'state' }) {
    const name = input.attributes.name.value;
    const value = input.value;
    const isCheckbox = input.attributes.type?.value == 'checkbox';

    let nameParts = this.inputNameParts(name);
    let memberNameParts = this.inputNameParts(memberName);

    let nameableNameParts = nameParts.filter((namePart) => {
      return !namePart.match(/\d+/);
    });

    let customSetterName = Infl.camelize(['set', ...nameableNameParts].join('_'), true);
    // console.log("customSetterName", customSetterName);
    let onChangedCallbackName = Infl.camelize(['on', ...nameableNameParts, 'changed'].join('_'), true);
    // console.log("onChangedCallbackName", onChangedCallbackName);
    let onChangedCallback = this[onChangedCallbackName];

    if (typeof this[customSetterName] == 'function') {
      this[customSetterName]({ [name]: value, _value: value, input: input });
    } else if (this.isNamePartsPrefixOfMemberNameParts(nameParts, memberNameParts)) {
      this.initializeMemberParts(memberNameParts);

      let currentNode = this[memberNameParts[0]];

      nameParts.slice(1, nameParts.length - 1).forEach((namePart) => {
        if(!currentNode[namePart]) {
          currentNode[namePart] = {};
        }

        currentNode = currentNode[namePart];
      });

      if(isCheckbox) {
        if(input.checked) {
          currentNode[nameParts[nameParts.length - 1]] = true;
        } else {
          currentNode[nameParts[nameParts.length - 1]] = false;
        }
      } else {
        try {
          currentNode[nameParts[nameParts.length - 1]] = JSON.parse(value);
        } catch {
          currentNode[nameParts[nameParts.length - 1]] = value;
        }
      }
    } else {
      console.warn(`Don't know what to do with ${name}. Setter name: ${customSetterName}.`);
    }

    return typeof onChangedCallback == 'function' && onChangedCallback;
  }

  isNamePartsPrefixOfMemberNameParts(nameParts, memberNameParts) {
    for(let i = 0; i < memberNameParts.length; i++) {
      if(!nameParts[i] || nameParts[i] != memberNameParts[i]) return false;
    }

    return true;
  }

  initializeMemberParts(memberNameParts) {
    let currentNode = this;

    memberNameParts.forEach((memberNamePart) => {
      if(!currentNode[memberNamePart]) {
        currentNode[memberNamePart] = {};
        currentNode = currentNode[memberNamePart];
      }
    });
  }

  modules() {
    // Can maybe js be MORE shitty?
    installAll();
  }

  updateRemoteTemplate(path) {
    API.ajax({
      url: `${path}.json`,
      method: 'GET',
      complete: ({ responseJSON }) => {
        if(!responseJSON || !responseJSON.template || !responseJSON.selector) return;

        $(responseJSON.selector).replaceWith(responseJSON.template);
      }
    });
  }

  clearFieldError($field) {
    const $group = $field.closest('.form-group');
    $group.removeClass('with-error');
    $group.find('.form-group-error').html('');
  }

  setFieldError($field, message) {
    const $group = $field.closest('.form-group');
    $group.addClass('with-error');
    $group.find('.form-group-error').html(message);
  }
}

export default ApplicationController;

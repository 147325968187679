import DestinationExportOptionsForm from 'destinations/base/export_options_form'

class WebflowExportOptionsForm extends DestinationExportOptionsForm {
  newDestinationExportTemplateName(usedTemplate, destination, exportOptions, originalExportOptions) {
    const collectionDisplayName = (destination?.meta?.collections || {})[exportOptions?.destination_meta?.collection_id]?.displayName;
    if(!collectionDisplayName) return super.newDestinationExportTemplateName(usedTemplate, destination, exportOptions);

    if(usedTemplate) {
      const oldCollectionDisplayName =
            (destination?.meta?.collections || {})[originalExportOptions?.destination_meta?.collection_id]?.displayName;

      return usedTemplate.name.replace(oldCollectionDisplayName, collectionDisplayName);
    }

    return `${destination.default_new_template_name} - ${collectionDisplayName}`;
  }
}

export default WebflowExportOptionsForm;

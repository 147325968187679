import DestinationsEditBaseController from "controllers/destinations/edit/base_controller"

export default class extends DestinationsEditBaseController {
  connect() {
    this.onConnect();
    this.ensureActiveCollectionTab();
  }

  ensureActiveCollectionTab() {
    this.setCollectionTab(
      this.getDefaultActiveCollectionTabId()
    );
  }

  onSetCollectionTab(e) {
    this.setCollectionTab($(e.target).data('collection-tab-id'));
  }

  setCollectionTab(collectionTabId) {
    this.$('.collection-tab').hide();
    this.$('.side-tab-link')
      .removeClass('side-tab-link-active');

    $(`#${collectionTabId}`).show();
    $(`#${collectionTabId}-link`)
      .addClass('side-tab-link-active');
  }

  onStateFieldsSearchChanged() {
    const $fieldsTable = this.findFieldsTable();
    const search = this.state.fields.search.toLowerCase();

    if(search.length == 0) {
      $fieldsTable.find('[data-field-search-matchable]').show();
      return;
    }

    $fieldsTable.find('[data-field-search-matchable]').each((i, tr) => {
      const $tr = $(tr);
      if($tr.data('field-search-matchable').includes(search)) {
        $tr.show();
      } else {
        $tr.hide();
      }
    })
  }

  findFieldsTable() {
    return this.$('.fieldable-field-mapping-table');
  }
}

import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['permissionsGif', 'firstPermission', 'secondPermission'];

  connect() {
    this.onConnect();

    this.$modal = this.$element.closest('.modal');
    if(this.$modal.length == 0) return;

    this.$modal.on('shown.bs.modal', this.positionPermissions);
    $(window).on('resize', this.positionPermissions);
  }

  disconnect() {
    this.$modal.off('shown.bs.modal', this.positionPermissions);
    $(window).off('resize', this.positionPermissions);
  }

  positionPermissions() {
    if(!this.$element.is(":visible")) return;

    const windowWidth = $(window).width();
    const wrapperWidth = this.$element.innerWidth();
    const gifHeight = this.$permissionsGif.height();
    const gifWidth = this.$permissionsGif.width();

    if(wrapperWidth == 0 || gifWidth == 0) setTimeout(this.positionPermissions, 400);

    if(windowWidth >= 576) {
      if(windowWidth >= 1200) {
        this.$firstPermission
          .css('top', gifHeight * 0.48)
          .css('left', gifWidth - 10)
          .width(wrapperWidth - gifWidth + 26);

        this.$secondPermission
          .css('top', gifHeight * 0.65)
          .css('left', gifWidth - 10)
          .width(wrapperWidth - gifWidth + 26);

      } else if(windowWidth >= 992) {
        this.$firstPermission
          .css('top', gifHeight * 0.34)
          .css('left', gifWidth - 10)
          .width(wrapperWidth - gifWidth + 26);

        this.$secondPermission
          .css('top', gifHeight * 0.67)
          .css('left', gifWidth - 10)
          .width(wrapperWidth - gifWidth + 26);
      } else if(windowWidth >= 576) {
        this.$firstPermission
          .css('top', gifHeight * 0.45)
          .css('left', 'auto')
          .width(wrapperWidth);

        this.$secondPermission
          .css('top', gifHeight * 0.85)
          .css('left', 'auto')
          .width(wrapperWidth);
      }

      $('.d-xs-none.d-sm-none.d-md-none.d-lg-block.d-xl-block').show();
    } else {
      this.$firstPermission
        .css('top', gifHeight * 0.45)
        .css('left', 'auto')
        .width(wrapperWidth);

      this.$secondPermission
        .css('top', gifHeight * 0.93)
        .css('left', 'auto')
        .width(wrapperWidth);

      $('.d-xs-none.d-sm-none.d-md-none.d-lg-block.d-xl-block').hide();
    }

    this.$firstPermission.show();
    this.$secondPermission.show();
  }
}

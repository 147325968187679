import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static modelName = 'DocumentImage';
  static targets = ['generateable', 'generateAttributesWithAiButton'];

  connect() {
    this.onConnect();
    this.checkGeneratedAttributes();
  }

  getGenerateableAttributeWrapperFromEvent(e) {
    const $target = $(e.target);
    const $generateable = $(e.target).closest('[data-document-image-card-target="generateable"]');
    const attribute = $generateable.data('attribute');

    return { $generateable, attribute };
  }

  checkGeneratedAttributes() {
    if(typeof this.state.document_image.generated_attributes != "object") return;

    const generateable = {};

    this.$generateable.each((i, el) => {
      const $el = $(el);
      const attribute = $el.data('attribute');
      generateable[attribute] = null;

      const generatedValue = this.state.document_image.generated_attributes[attribute]?.value;
      generateable[attribute] = generatedValue;

      const $withGenerated = $el.find('.document-image-with-generated');

      if(generatedValue) {
        $withGenerated.find('.document-image-generated-value').text(generatedValue);
        $withGenerated.fadeIn();
      }
    });

    const notGeneratedValues = Object.values(generateable).filter((value) => {
      return value == null;
    }).length;

    if(notGeneratedValues > 0) {
      this.$generateAttributesWithAiButton.show();
    } else {
      this.$generateAttributesWithAiButton.hide();
    }
  }

  generateAttributes() {
    this.disableElement();

    API.ajax({
      url: `/document_images/${this.state.document_image.id}/generate_attributes`,
      method: 'post',
      complete: (response) => {
        if(typeof response.responseJSON.document_image.generated_attributes == "object")
          this.state.document_image.generated_attributes = response.responseJSON.document_image.generated_attributes;

        this.checkGeneratedAttributes();
        this.enableElement();
      }
    });
  }

  useGeneratedAttribute(e) {
    const { $generateable, attribute } = this.getGenerateableAttributeWrapperFromEvent(e);
    const value = this.state.document_image.generated_attributes[attribute].value;
    const $field = $generateable.find(`[name="document_image[${attribute}]"]`);

    $field.changeTo(value);
  }
}

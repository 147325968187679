import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['tagsWrapper', 'tagsTrigger']

  connect() {
    this.onConnect();

    if(this.$tagsWrapper) {
      this.$tagsWrapper.find("[data-role=tagsinput]").tagsinput();
      this.$tagsWrapper.on('beforeItemAdd', this.addTag)
      this.$tagsWrapper.on('beforeItemRemove', this.destroyTag)
    }

    this.documentId = this.$element.data('document-id');
    this.addTagPath = this.$element.data('add-tag-path');
    this.removeTagPath = this.$element.data('remove-tag-path');

    this.updateTagTriggerColor();
  }

  addTag(event) {
    $.ajax({
      url: this.addTagPath,
      method: 'PUT',
      data: { tag: event.item },
      complete: this.updateTagTriggerColor
    })
  }

  destroyTag(event) {
    $.ajax({
      url: this.removeTagPath,
      method: 'DELETE',
      data: { tag: event.item },
      complete: this.updateTagTriggerColor
    })
  }

  tagCount() {
    if(!this.$tagsWrapper) return 0;
    return this.$tagsWrapper.find('.tag-item').length;
  }

  updateTagTriggerColor() {
    if(!this.$tagsWrapper) return;
    if (this.tagCount() > 0) {
      this.$tagsTrigger.addClass('text-purple-base').removeClass('text-gray-300')
    } else {
      this.$tagsTrigger.removeClass('text-purple-base').addClass('text-gray-300')
    }
  }
}

import DestinationsEditFieldableController from "controllers/destinations/edit/fieldable_controller"

export default class extends DestinationsEditFieldableController {
  connect() {
    this.onConnect();
    this.currentEnvironmentId = this.state.default_environment_id;
    super.connect();
  }

  getDefaultActiveCollectionTabId() {
    const contentTypeId = this.state
          .environments[this.currentEnvironmentId]
          .default_content_type_id;

    return `environment-${this.currentEnvironmentId}-collection-${contentTypeId}-tab`;
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [
    'documentsWidget',
    'exportsWidget',
    'exportQueueWidget',
    'exportQueueAction',
    'exportSelectionButton',
    'oneClickExportButton',
    'emptySelectedToExport',
    'onboardingImportingHelper',
    'onboardingExportingHelper'
  ];

  connect() {
    this.onConnect({ jqueryfyTargets: this.hasDocumentsWidgetTarget });
    document.addEventListener("WatchedFieldsBroadcaster", this.receiveWatchedFieldsBroadcasterEvent);

    const params = new URLSearchParams(window.location.search);

    // Helper to pretend I have fewer exports left
    if(params.has('simulate_exports_left')) {
      this.simulateExportsLeft = parseInt(params.get('simulate_exports_left'));
    }

    this.setDocumentIdExportListFromQueryParam();

    // Little Helper to show the "selected to export" quickly
    this.devQuickSelectToExport = false;

    if(this.devQuickSelectToExport) {
      this.devQuickSelectToExportInterval = setInterval(() => {
        const documentWidgetCards = $(this.$documentsWidget).find('[data-document-id]');

        if(documentWidgetCards.length > 0) {
          this.ensureSelectedToExportDocument(documentWidgetCards.next().next().data('document-id'));
          clearInterval(this.devQuickSelectToExportInterval);
        }
      }, 1000);
    }
  }

  disconnect() {
    document.removeEventListener("WatchedFieldsBroadcaster", this.receiveWatchedFieldsBroadcasterEvent);
  }

  clearDocumentIdExportList() {
    this.documentIdExportList = [];
    this.setDocumentIdExportListQueryParam();
  }

  setDocumentIdExportListFromQueryParam() {
    const params = new URLSearchParams(window.location.search);

    try {
      this.documentIdExportList = params.get('export_queue_document_ids').split(',').map((documentId) => {
        return parseInt(documentId);
      });
    } catch {
      this.documentIdExportList = [];
    }

    if(this.documentIdExportList.length == 0) return;

    this.setDocumentIdExportListQueryParam();
    this.showExportQueueWidget();
  }

  setDocumentIdExportListQueryParam() {
    if(history.pushState) {
      var searchParams = new URLSearchParams(window.location.search);

      if(Array.isArray(this.documentIdExportList) && this.documentIdExportList.length > 0) {
        searchParams.set("export_queue_document_ids", this.documentIdExportList.join(','));
      } else {
        searchParams.delete("export_queue_document_ids");
      }

      var searchPart = searchParams.toString();

      searchPart = searchPart.replaceAll('%2C', ',');

      if(searchPart.length > 0) {
        searchPart = `?${searchPart}`;
      }

      var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + searchPart;
      window.history.replaceState({ path: newUrl }, '', newUrl);
    }
  }

  cancelExporting() {
    const columnToBeFadedIn = this.state.onboarder?.step == 3 ?
          this.$onboardingExportingHelper :
          this.$exportsWidget;

    this.clearDocumentIdExportList();

    this.$exportQueueWidget.fadeOut(() => {
      $('.widget-body-container-list', this.$exportQueueWidget).html('');
      columnToBeFadedIn.fadeIn();
    })

    this.triggerDocumentsWidgetSearch();
  }

  triggerDocumentsWidgetSearch() {
    this.$documentsWidget.find('.widget').dispatch('search');
  }

  triggerExportsWidgetSearch() {
    this.$exportsWidget.find('.widget').dispatch('search');
  }

  addToExportList(documentId) {
    if(this.documentIdExportList.includes(documentId)) return;

    this.documentIdExportList.push(documentId);
    this.setDocumentIdExportListQueryParam();
    this.setSelectedToExportAction();
  }

  removeFromExportList(documentId) {
    const documentIdIndex = this.documentIdExportList.indexOf(documentId);
    if(documentIdIndex == -1) return;

    this.documentIdExportList.splice(documentIdIndex, 1);
    this.setDocumentIdExportListQueryParam();
    this.setSelectedToExportAction();
  }

  setSelectedToExportAction() {
    if(this.documentIdExportList.length == 0) {
      $.crossFade(
        this.$exportQueueAction,
        this.$emptySelectedToExport
      )
    } else {
      this.$emptySelectedToExport.hide();
      this.$exportQueueAction.fadeIn();

      const destinationExportTemplatesIds = this.getDestinationExportTemplatesIds();
      const detiType = typeof destinationExportTemplatesIds

      if(destinationExportTemplatesIds != undefined &&
         (detiType == 'number' || (destinationExportTemplatesIds.length > 0 && destinationExportTemplatesIds[0] != ''))) {
        // this.$exportSelectionButton.find('span').hide();
        this.$exportSelectionButton.show().addClass('link text-right').removeClass('btn btn-primary btn-lg btn-block');
        this.$oneClickExportButton.show();
        this.showMultiTemplateOneClickExportMessage();
      } else {
        // this.$exportSelectionButton.find('span').show();
        this.$exportSelectionButton.show().addClass('btn btn-primary btn-lg btn-block').removeClass('link text-right');
        this.$oneClickExportButton.hide();
      }
    }
  }

  onStateDestinationExportTemplateIdChanged() {
    this.setSelectedToExportAction();
  }

  getDestinationExportTemplatesIds() {
    let destinationExportTemplateIds = this.state.destination_export_template_id;

    if(destinationExportTemplateIds == undefined) destinationExportTemplateIds = [];

    if(typeof destinationExportTemplateIds.map == 'function')
      return destinationExportTemplateIds;

    return [destinationExportTemplateIds];
  }

  exportSelection() {
    if(this.documentIdExportList.length == 0) {
      return;
    }

    const queryParams = [`document_ids=${this.documentIdExportList.join(',')}`];

    if(this.simulateExportsLeft != undefined) {
      queryParams.push(`simulate_exports_left=${this.simulateExportsLeft}`);
    }

    const destinationExportTemplatesIds = this.getDestinationExportTemplatesIds();

    if(destinationExportTemplatesIds.length > 0) {
      queryParams.push(`destination_export_template_id=${destinationExportTemplatesIds[0]}`);
    }

    window.location = `/exports/new?${queryParams.join('&')}`;
  }

  confirmQuickExportSelection() {
    if(this.documentIdExportList.length == 0) {
      return;
    }

    if(Wordable.global.current_user.settings.hide_one_click_export_confirmation) {
      this.oneClickExportSelection();
    } else {
      dataConfirmModal.confirm({
        text: $('#create-from-destination-export-template-confirmation-modal').html(),
        onConfirm: this.oneClickExportSelection,
      });
    }
  }

  oneClickExportSelection() {
    let destinationExportTemplateIds = this.getDestinationExportTemplatesIds();

    const exportsAttributes = destinationExportTemplateIds.map((destination_export_template_id) => {
      return {
        destination_export_template_id: destination_export_template_id,
        documents_ids: this.documentIdExportList
      }
    });

    this.disableNode(this.$exportQueueWidget);

    const requestData = { exports_attributes: exportsAttributes };

    if(this.simulateExportsLeft != undefined) {
      requestData.simulate_exports_left = this.simulateExportsLeft;
    }

    API.ajax({
      url: `/exports/create_from_destination_export_template`,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify(requestData),
      complete: (e) => {
        setTimeout(() => {
          this.enableNode(this.$exportQueueWidget);

          if(e.responseJSON?.modal) {
            return;
          }

          this.cancelExporting();
          this.triggerExportsWidgetSearch();
        }, 400);
      }
    });
  }

  ensureSelectedToExportDocument(documentId) {
    let $exportQueueDocumentWidgetCard = $(
      `[data-template-id=document-widget-card_${documentId}]`,
      this.$exportQueueWidget
    );

    if($exportQueueDocumentWidgetCard.length > 0) {
      return $exportQueueDocumentWidgetCard.lookAtMe();
    }

    let $documentWidgetCard = $(
      `[data-template-id=document-widget-card_${documentId}]`,
      this.$documentsWidget
    );

    $documentWidgetCard.find('[data-toggle="popover"]').popover('hide');
    $documentWidgetCard = $documentWidgetCard.detach();
    this.stopLookingAtExportButtons();

    this.showExportQueueWidget(() => {
      $documentWidgetCard.appendTo($('.widget-body-container-list', this.$exportQueueWidget));
      this.addToExportList(documentId);
    });
  }

  showExportQueueWidget(callback) {
    const columnToBeFadedOut = this.state.onboarder?.step == 3 ?
          this.$onboardingExportingHelper :
          this.$exportsWidget;

    $.crossFade(columnToBeFadedOut,
                this.$exportQueueWidget,
                () => {
                  if(typeof callback == 'function') callback();
                  this.$exportQueueWidget.find('[data-controller="export-queue-widget"]').dispatch('show');
                });
  }

  selectBulkExport(e) {
    e.detail.documents.forEach((document) => {
      this.ensureSelectedToExportDocument(document.id);
    })
  }

  selectToExport(e) {
    e.stopPropagation();
    const documentId = $(e.target).parents('[data-document-id]').data('document-id');
    if(!documentId) return;

    this.ensureSelectedToExportDocument(documentId);
  }

  documentsContainerList() {
    // Doesn't exist onConnect

    if(!this.$documentsContainerList) {
      this.$documentsContainerList = $('.widget-body-container-list', this.$documentsWidget);
    }

    return this.$documentsContainerList;
  }

  stopLookingAtExportButtons() {
    this.$documentsWidget.find('.document-widget-card-select-to-export-button').stopLookingAtMe();
  }

  removeFromExport(e) {
    const documentId = $(e.target).parents('[data-document-id]').data('document-id');

    if(!documentId) return;

    let $documentWidgetCard = $(
      `[data-template-id=document-widget-card_${documentId}]`,
      this.$exportQueueWidget
    );

    this.removeFromExportList(documentId);
    $documentWidgetCard.find('[data-toggle="popover"]').popover('hide');
    $documentWidgetCard = $documentWidgetCard.detach();

    const $documentsContainerList = this.documentsContainerList();
    if($documentsContainerList.find(`[data-document-id=${documentId}]`).length > 0) return;

    $documentWidgetCard.prependTo(this.documentsContainerList());
  }

  showMultiTemplateOneClickExportMessage() {
    if(this.shownMultiTemplateOneClickExportMessage ||
       Wordable.global.current_user.settings.hide_multi_template_one_click_export_message) {
      return;
    }

    this.shownMultiTemplateOneClickExportMessage = true;
    this.$oneClickExportButton.popover('show');
  }

  /* Onboarding Helpers */
  receiveWatchedFieldsBroadcasterEvent(event) {
    const { global_event, payload } = event.detail;

    if (payload.model_name == 'Document') {
      /* This is the first import! Make sure we hide onboardingImportingHelper and show documentsWidget */
      if (this.state.onboarder?.step == 2) {
        $.crossFade(
          this.$onboardingImportingHelper,
          this.$documentsWidget,
          () => {
            this.$onboardingExportingHelper.fadeIn();
          }
        )

        if(this.state.onboarder) {
          this.state.onboarder.step = 3;
        }

        $('.nav-onboarding-step-2').removeClass('nav-onboarding-step-active');
        $('.nav-onboarding-step-3').addClass('nav-onboarding-step-active');
      }
    }
  }
}

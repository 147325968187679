import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  connect() {
    this.onConnect();
  }

  ensure() {
    API.ajax({
      url: '/current_team/ensure_wordable_branding_transformation_on_all_export_destination_templates',
      method: 'POST'
    });
  }

  remove() {
    if(Wordable.global.current_team.force_wordable_branding) {
      return this.blockWithSubscriptionCta({ variant: 'wordable-branding' });
    }

    API.ajax({
      url: '/current_team/remove_wordable_branding_transformation_from_all_export_destination_templates',
      method: 'POST'
    });
  }
}

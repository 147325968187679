export default class PriceModel {
  constructor({ freeN, c1, s, l, e, upperLimit = 0 }) {
    this.freeN = freeN;
    this.s = s;
    this.l = l;
    this.e = e;
    this.c1 = c1;
    this.cCache = {};
    this.upperLimit = upperLimit;
  }

  extraN(n) {
    return Math.max(0, n - this.freeN);
  }

  c(n) {
    let extra = this.extraN(n);
    if(extra <= 0) return 0;
    if(extra == 1) return this.c1;
    if(this.upperLimit > 0 && extra > this.upperLimit) return this.c(this.upperLimit)

    if(!this.cCache[n]) {
      let value = (this.c(n - 1) + this.marginalCost(n));
      this.cCache[n] = Math.round((value) * 100) / 100;
    }

    return this.cCache[n];
  }

  marginalCost(n) {
    let extra = this.extraN(n)

    if(extra == 0) return 0;
    if(extra == 1) return this.c1;

    let value = this.l + this.e * this.s / (extra + this.s);
    return Math.round((value) * 100) / 100;
  }
}

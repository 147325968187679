import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['form',
                    'saving',
                    'saved',
                    'changed',
                    'disableable',
                    'loadingHideable'];

  connect() {
    this.onConnect();

    if(this.state?.statuses_wrapper) {
      this.$saved = $(this.state.statuses_wrapper).find('[data-autosave-entity-form-target=saved]');
      this.$changed = $(this.state.statuses_wrapper).find('[data-autosave-entity-form-target=changed]');
      this.$saving = $(this.state.statuses_wrapper).find('[data-autosave-entity-form-target=saving]');
      this.$disableable = $(this.state.statuses_wrapper).find('[data-autosave-entity-form-target=disableable]');
      this.$loadingHideable = $(this.state.statuses_wrapper).find('[data-autosave-entity-form-target=loadingHideable]');
    }

    this.$saved?.hide();
    this.$saving?.hide();
    this.$changed?.hide();

    this.saveDelay = this.$saved ? 800 : 0;
  }

  inputChanged() {
    if(this.autosaveTimeout) {
      clearTimeout(this.autosaveTimeout);
    }

    this.setStatus(this.$changed);
    this.$disableable?.attr('disabled', true);
    this.$loadingHideable?.hide();
    this.autosaveTimeout = setTimeout(this.save, this.saveDelay);
  }

  save() {
    this.setStatus(this.$saving);
    this.$disableable?.attr('disabled', true);
    this.$loadingHideable?.hide();

    const data = this.$form.serialize();
    const url = this.$form.attr('action');

    $.ajax({
      url: `${Wordable.global.API_HOST}/${url}.json`,
      data,
      method: 'PUT',
      success: this.handleSaveSuccess,
    });
  }

  setStatus($statusBadge) {
    if(!$statusBadge) return;

    this.$saving?.hide();
    this.$saved?.hide();
    this.$changed?.hide();

    $statusBadge.show();
  }

  handleSaveSuccess() {
    this.setStatus(this.$saved);
    this.$disableable?.removeAttr('disabled', true);
    this.$loadingHideable?.hide();

    this.$saved?.animate({ opacity: 1 }, () => {
      if(this.$loadingHideable) {
        setTimeout((() => {
          this.$saved?.hide();
          this.$loadingHideable?.show();
        }), 1000);
      } else {
        this.$saved?.animate({ opacity: 0 });
      }
    });
  }
}

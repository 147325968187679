import WidgetController from "controllers/widget_controller";

export default class extends WidgetController {
  static targets = ['container', 'loading', 'empty']
  static modelName = 'SystemEvent';

  connect() {
    this.skipSearch = true;
    this.initialSearch = true;
    this.onConnect();

    this.$dropdown = this.$element.parents('.nav-item.dropdown');

    if(this.$dropdown.length == 0) {
      this.onShown();
    } else {
      this.$dropdown.on('show.bs.dropdown', this.onShown);
    }
  }

  disconnect() {
    this.$dropdown.off('show.bs.dropdown', this.onShown);
  }

  onShown() {
    if(this.initialSearch) {
      this.initialSearch = false;
      this.skipSearch = false;
    }

    this.search();
    this.skipSearch = true;
  }

  isSearchable() {
    return !this.skipSearch;
  }

  toggleReadState(e) {
    e.stopPropagation();
    const { $widgetCard, system_event } = this.getInstanceFromEvent(e);

    this.disableNode($widgetCard);

    const systemEventAttributes = { read: !system_event.read };

    API.ajax({
      url: `/system_events/${system_event.id}`,
      method: 'PUT',
      data: { system_event: systemEventAttributes },
      complete: () => {
        $widgetCard.remove();
        this.ensureNewNotificationsState();
      }
    });
  }

  readAll(e) {
    e.stopPropagation();
    this.disableElement();

    API.ajax({
      url: `/system_events/read_all`,
      method: 'PUT',
      complete: () => {
        this.clearSearchTimeoutAndSearch();
      }
    });
  }

  ensureNewNotificationsState() {
    const $widgetCards = this
          .$container
          .find('.notifications-widget-new-notifications:first')
          .find('.widget-card');

    if($widgetCards.length == 0) {
      this.$(this.emptyTarget).show();
    } else {
      this.$(this.emptyTarget).hide();
    }
  }

  appendOrderedWidgetCard($newWidgetCard, $targetList, systemEventAttributes) {
    if(this.initialSearch) return;

    const newWidgetCardReusedAt = new Date(systemEventAttributes.reused_at);

    const $widgetCards = $targetList
          .find('.widget-card');

    if($widgetCards.length == 0) {
      return $targetList.prepend($newWidgetCard);
    }

    let appended = false;

    $widgetCards.each((i, widgetCard) => {
      const $widgetCard = $(widgetCard);
      const widgetCardReusedAt = new Date($widgetCard.data('system_event')['reused_at']);

      if(widgetCardReusedAt < newWidgetCardReusedAt) {
        $widgetCard.before($newWidgetCard);
        appended = true;
        return false;
      }
    });

    if(!appended) {
      $targetList.append($newWidgetCard);
    }
  }

  receiveWatchedFieldsBroadcasterEvent({ detail: { payload } }) {
    if (payload.model_name == 'SystemEvent') {
      const { templates, ...systemEventAttributes} = payload;
      this.$(templates.system_event.widget_card.selector).remove();
      let $target = $(templates.system_event.widget_card.template);

      const $targetList = payload.read ?
            this.$container.find('.notifications-widget-read-notifications:first') :
            this.$container.find('.notifications-widget-new-notifications:first');

      this.appendOrderedWidgetCard($target, $targetList, systemEventAttributes);

      this.$container.show();
      this.$loading.hide();
      this.modules();
      this.ensureNewNotificationsState();
    }
  }
}

import ApplicationController from "controllers/application_controller";
// import GoogleDriveAuth from "connections/google_drive/auth";

export default class extends ApplicationController {
  static targets = []

  connect() {
    this.onConnect();
    this.$beforeAuthModal = $(`#${this.state.external_service.category}-before-auth-modal`);

    // if(this.state.external_service.category == 'google_drive')
    //   this.serviceAuth = new GoogleDriveAuth();
  }

  confirm(e) {
    if(this.$beforeAuthModal.length > 0) {
      $.openModal({
        title: 'Just a sec!',
        body: this.$beforeAuthModal.find('.modal-body').html(),
        footer: this.$beforeAuthModal.find('.modal-footer').html(),
        onShow: (modal) => {
          modal.find('.btn-primary').on('click', () => {
            this.act();
          });

          $(".btn-primary").delay(1200).animate({ opacity: 1 });
        }
      });
    } else if(this.serviceAuth) {
      this.serviceAuth.auth();
    } else {
      this.act();
    }
  }

  act() {
    if(this.state.confirmed_href) {
      window.location = this.state.confirmed_href;
    } else {
      this.create();
    }
  }

  create() {
    this.disableElement();

    API.ajax({
      url: `connections.json`,
      method: 'post',
      data: {
        connection: {
          description: this.state.external_service.name,
          external_service_id: this.state.external_service.id
        }
      },
      complete: (response) => {
        if(response.responseJSON.auth_connection_path)
          window.location = response.responseJSON.auth_connection_path;
      }
    })
  }
}

function onAjaxComplete(options, response, status) {
  if(typeof Messaging != 'undefined') {
    Messaging.interpretJSON(response.responseJSON);
  }

  if(typeof options.complete == 'function') {
    options.complete(response, status)
  }
}

export default {
  ajax(options) {
    options = {
      dataType: 'json',
      contentType: 'application/json',
      ...options
    }

    if(options.dataType == 'json' && options.data && typeof options.data != 'string') {
      options.data = JSON.stringify(options.data);
    }

    $.ajax({ ...options, complete: (response, status) => {
      return onAjaxComplete(options, response, status);
    }})
  }
}

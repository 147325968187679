import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [
    'cardInfo',
    'cardFields'
  ];

  connect() {
    this.onConnect();
  }

  showCardFields() {
    this.$cardInfo.hide();
    this.$cardFields.show();
  }

  onStripeCardInputReady() {}

  showCardInfo() {
    this.$cardInfo.show();
    this.$cardFields.hide();
  }

  onStripeCardError(e) {
    this.enableElement();
  }

  onStripeCardSuccess(e) {
    const cardToken = e.detail.result?.paymentMethod?.id;

    if(!cardToken) {
      this.enableElement();
      return;
    }

    if(this.state.update_current_team) {
      this.disableElement();

      API.ajax({
        url: '/current_team',
        method: 'PUT',
        data: { card_token: cardToken },
        complete: (response) => {
          window.location.reload();
        }
      });
    }
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [
    'promotionCodeInput',
    'subscribeButton'
  ];

  connect() {
    this.onConnect();
    this.updateSubscribeButtonState();
  }

  setTeamAgreedToTermsOfService({ input }) {
    this.state.team.agreed_to_terms_of_service = $(input).is(':checked');
    this.updateSubscribeButtonState();
  }

  showPopup() {
    if(this.state.team.agreed_to_terms_of_service) return;

    this.$subscribeButton.popover('show');
  }

  hidePopup() {
    this.$subscribeButton.popover('hide');
  }

  updateSubscribeButtonState() {
    this.$subscribeButton.prop('disabled', !this.state.team.agreed_to_terms_of_service);
  }

  addPromotionCode(e) {
    if((e?.keyCode && e?.keyCode != 13) || !this.$promotionCodeInput) return;
    e.preventDefault();

    let promotionCode = this.$promotionCodeInput.val();

    if(!promotionCode || promotionCode.length == 0) promotionCode = null;

    this.disableElement();
    HREF.goTo({ coupon: promotionCode });
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  connect() {
    this.onConnect();

    this.updateAbTestMetaSignupPageVisitedAt();
  }

  updateAbTestMetaSignupPageVisitedAt() {
    API.ajax({
      url: `/ab_tests/update_current_ab_test`,
      method: 'put',
      data: {
        meta: {
          signup_page_visited_at: new Date()
        }
      }
    });
  }
}

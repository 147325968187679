// POC For Table Export Thing

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  connect() {
    this.onConnect();
    this.$container = $(this.containerTarget);
  }

  highlightDocumentAndDestination(e) {
    const target = $(e.target).parents('[data-document-id]');

    const documentId = target.data('document-id');
    const destinationId = target.data('destination-id');

    if(documentId && destinationId) {
      $(`#destination_${destinationId}`).addClass('highlighted text-red-400 text-xl bg-white');
      $(`#document_${documentId}`).addClass('highlighted text-red-400 text-xl bg-white');
    }
  }

  removeHighlights() {
    $('.highlighted').removeClass('text-red-400 text-hg bg-white');
  }
}

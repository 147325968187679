import ApplicationController from "controllers/application_controller"
import WistiaVideoAdapter from "players/wistia";

export default class extends ApplicationController {
  static targets = ['playerWrapper'];

  connect() {
    this.onConnect();
    this.videos = {};
    this.$element.find('[data-video-id]').each((i, videoTrigger) => {
      const $videoTrigger = $(videoTrigger);
      const videoId = $videoTrigger.data('video-id');

      this.videos[videoId] = {
        videoId: videoId,
        trigger: $videoTrigger,
        adapter: new WistiaVideoAdapter(videoId, this.$playerWrapper, { wrapperWidth: this.$element.innerWidth() })
      };
    });
  }

  toggleVideoModal(e) {
    if(this.$element.is('.player-open')) return this.closeVideoModal();
    this.openVideoModal(e);
  }

  openVideoModal(e) {
    if(this.$element.is('.player-open')) return;

    const $target = $(e.target).closest('[data-video-id]');
    const videoId = $target.data('video-id');
    const videoSeek = $target.data('video-seek');

    if(!this.videos[videoId]) return;

    if(this.videos[this.currentVideoId]) this.videos[this.currentVideoId].adapter.pause();

    ga4TrackEvent({ target: 'video_tutorial_open', video_id: videoId });

    this.currentVideoId = videoId;
    this.videos[videoId].adapter.play(videoSeek);
    this.$element.addClass('player-open');
  }

  closeVideoModal() {
    this.$element.removeClass('player-open');
    if(this.videos[this.currentVideoId]) this.videos[this.currentVideoId].adapter.pause();
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  connect() {
    this.onConnect();
  }

  openChat() {
    if(typeof Intercom == undefined) return;

    let text = this.state?.text;

    if(text) {
      Intercom('showNewMessage', text);
    } else {
      Intercom('show');
    }
  }
}

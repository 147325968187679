window.getCookie = function(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');

  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

document.addEventListener("turbolinks:load", () => {
  installAll();

  // handle ajax loaded modal
  $(document).on('click', '.ajax-modal', function (e) {
    e.preventDefault();
    const data = $(this).data();

    $.get(data.url, function (html) {
      const modal = $('#default-modal');
      modal.find('.modal-title').html(data.title);
      modal.find('.modal-body').html(html);
      modal.modal();
    });
  });

  feather.replace();

  $("form").validate();

  if (history.replaceState && window.URLSearchParams) {
    let searchParams = new URLSearchParams(window.location.search);
    const removableKeys = [];

    for (const key of searchParams.keys()) {
      if(key.startsWith('utm_')) {
        removableKeys.push(key);
      }
    }

    removableKeys.forEach((key) => {
      searchParams.delete(key);
    })

    if(removableKeys.length > 0) {
      let query = searchParams.toString();
      let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;

      if(query.length > 0) {
        newUrl += '?' + query;
      }

      window.history.replaceState({ path: newUrl }, '', newUrl);
    }
  }
});

document.addEventListener("turbolinks:before-cache", function() {
  if (toastr) {
    toastr.remove();
  }

  $('.g-recaptcha').empty();
});

import ChromeExtensionController from "controllers/chrome_extension_controller";

export default class extends ChromeExtensionController {
  connect() {
    this.onConnect();
    installAll();

    chrome.runtime.onMessage.addListener(this.receiveMessage);
    this.ensureServiceWorkerWebSocket();
  }

  import() {
    this.$('.import-widget-card-import-button').remove();

    API.ajax({
      method: 'POST',
      url: `${Wordable.global.API_HOST}/imports/import_document`,
      data: { trigger: 'chrome_extension', ...this.state }
    });
  }

  export() {
    const documentId = this.state.external_document.imported_document_id;

    chrome.tabs.create({
      url: `${Wordable.global.API_HOST}/exports/new?document_ids=${documentId}`
    });
  }

  receiveWatchedFieldsBroadcasterEvent({ detail: { payload } }) {
    if (payload.model_name != 'Document') return;

    this.element.dispatchEvent(
      new CustomEvent('reload', {
        detail: {
          originalSourceDocumentId: this.state.external_document.id
        }
      })
    );
  }
}

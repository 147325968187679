import WidgetController from "controllers/widget_controller";

export default class extends WidgetController {
  static targets = [
    'container',
    'loading',
    'empty',
    'pageInput',
    'bulkActions',
    'bulkSelectionToggler'
  ];

  static modelName = 'Export';
  static deleteDetailText = "This won't delete the exported posts, just Wordable records."

  connect() {
    this.onConnect();
  }

  onStateSearchDestinationIdChanged() {
    this.clearSearchTimeoutAndSearch();
  }

  onStateSearchOrderChanged() {
    this.clearSearchTimeoutAndSearch();
  }

  onStateSearchStateChanged() {
    this.clearSearchTimeoutAndSearch();
  }

  onStateSearchQueryStringChanged() {
    this.timeoutedSearch();
  }

  onStateSearchPerPageChanged() {
    this.clearSearchTimeoutAndSearch();
  }

  onStateSearchPageChanged() {
    this.clearSearchTimeoutAndSearch();
  }

  confirmReExport(e) {
    const instance = this.getInstanceFromEvent(e);

    $.openAjaxModal(`/exports/${instance.export.id}/reexport_options.json`, `Reexport ${instance.export.document_name}`, null);
  }

  receiveWatchedFieldsBroadcasterEvent({ detail: { payload } }) {
    if (payload.model_name == 'Export') {
      $(payload.templates.export.widget_card.selector).remove();
      let $target = $(payload.templates.export.widget_card.template);
      this.$container.find('.widget-body-container-list').prepend($target);
      $target.lookAtMe();
      this.modules();
    }
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['placeholder', 'input'];

  connect() {
    this.onConnect();
  }

  show() {
    this.$input.show();
    this.$placeholder.hide();
  }

  hide() {
    this.$input.hide();
    this.$placeholder.show();
  }
}

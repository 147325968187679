window.Messaging = {
  interpretFlash() {
    if(Wordable.global.flash) {
      Object.entries(Wordable.global.flash).forEach(([key, contents]) => {
        if(typeof contents == 'object') {
          Messaging.interpretJSON(contents);
          // $.infoModal(contents); TODO: Modal is object????? Naonde
        } else if(typeof toastr[key] == 'function' && typeof contents == 'string' && contents.length > 0) {
          toastr[key](contents);
        }
      })
    }
  },

  interpretJSON(json) {
    if(json?.toastr?.messages) {
      json.toastr.messages.forEach((message) => {
        if(message.text != '') toastr[message.type](message.text);
      })
    }

    if(json?.modal) {
      $.openModal(json?.modal);
    }
  }
}

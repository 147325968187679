const DocumentsConcern = {
  allDocuments() {
    const documents = [];

    this.$element.find('[data-document]').each((index ,node) => {
      documents.push($(node).data('document'));
    });

    return documents;
  },

  showDocument(e) {
    const documentsIds = this.allDocuments().map((document) => {
      return document.id;
    });

    const { document } = this.getInstanceFromEvent(e, 'document');

    this.openDocumentsCarrouselModal(documentsIds, document?.id);
  },

  openDocumentsCarrouselModal(documentsIds, selectedDocumentId) {
    if (!selectedDocumentId) {
      selectedDocumentId = documentsIds[0];
    }

    $('#documents-collection-details-modal').remove();
    $('#loading-modal').modal();

    $.get(`/documents/carrousel?document_ids=${documentsIds.join(',')}&selected_document_id=${selectedDocumentId}`,
          (response) => {
            $('#loading-modal').modal('hide');
            const newDocumentsCollectionDetailsModal = $(response.template);
            newDocumentsCollectionDetailsModal.prependTo('body');
            newDocumentsCollectionDetailsModal.modal();
            this.modules();
          });
  },
}

export default DocumentsConcern;

import ApplicationController from "controllers/application_controller"
import ImportFolderConcern from "controllers/concerns/import_folder";

export default class extends ApplicationController {
  static concerns = [ImportFolderConcern];
  static targets = ['sourceConnections', 'destinationConnections'];

  connect() {
    this.onConnect();

    document.addEventListener(
      "WatchedFieldsBroadcaster",
      this.receiveWatchedFieldsBroadcasterEvent
    );
  }

  getConnectionFromEvent(e) {
    const $target = $(e.target);

    const $widgetCard = $target.closest(`[data-connection]`);
    const connection = $widgetCard.data('connection');

    return { $widgetCard, connection };
  }

  confirmDestroyConnection(e) {
    const { $widgetCard, connection } = this.getConnectionFromEvent(e);
    const detailText = `<div class="text-sm mt-h text-gray-500 italic">This action cannot be undone.</div>`;

    dataConfirmModal.confirm({
      text: `<div class="text-red-base text-lg mb-h">You are about to delete "${connection.description}"</div> ${detailText}`,
      onConfirm: () => {
        this.disableNode($widgetCard);
        this.destroyConnection($widgetCard, connection.id);
      },
    });
  }

  destroyConnection($widgetCard, connectionId) {
    API.ajax({
      url: `/connections/${connectionId}`,
      method: 'DELETE',
      complete: () => {
        return $widgetCard.remove();
      }
    });
  }

  getSourceFromEvent(e) {
    const $target = $(e.target);

    const $widgetCard = $target.closest(`[data-source]`);
    const source = $widgetCard.data('source');

    return { $widgetCard, source };
  }

  getDestinationFromEvent(e) {
    const $target = $(e.target);

    const $widgetCard = $target.closest(`[data-destination]`);
    const destination = $widgetCard.data('destination');

    return { $widgetCard, destination };
  }

  confirmDestroySource(e) {
    const { $widgetCard, source } = this.getSourceFromEvent(e);
    const detailText =
          `<div class="text-sm mt-h text-gray-500 italic">You can add it again anytime from the dashboard</div>`;

    dataConfirmModal.confirm({
      title: 'Remove from sources?',
      text: `${detailText}`,
      // commit: `Stop Watching ${source.name}`,
      // cancel: "Keep Watching",
      onConfirm: () => {
        this.disableNode($widgetCard);
        this.destroySource($widgetCard, source.id);
      },
    });
  }

  confirmDestroyDestination(e) {
    const { $widgetCard, destination } = this.getDestinationFromEvent(e);

    const detailText =
          `<div class="text-sm mt-h text-gray-500 italic">This action cannot be undone</div>`;

    dataConfirmModal.confirm({
      title: 'Remove destination?',
      text: `${detailText}`,
      onConfirm: () => {
        this.disableNode($widgetCard);
        this.destroyDestination($widgetCard, destination.id);
      },
    });
  }

  destroySource($widgetCard, sourceId) {
    API.ajax({
      url: `/sources/${sourceId}`,
      method: 'DELETE',
      complete: () => {
        return $widgetCard.remove();
      }
    });
  }

  destroyDestination($widgetCard, destinationId) {
    API.ajax({
      url: `/destinations/${destinationId}`,
      method: 'DELETE',
      complete: () => {
        return $widgetCard.remove();
      }
    });
  }

  syncDestinations(e) {
    const { $widgetCard, connection } = this.getConnectionFromEvent(e);

    $widgetCard.addClass('syncing-connection');

    API.ajax({
      url: `/connections/${connection.id}/sync_destinations`,
      method: 'post'
    });
  }

  syncDestination(e) {
    const { $widgetCard, destination } = this.getDestinationFromEvent(e);

    $widgetCard.addClass('syncing-connection');

    API.ajax({
      url: `/destinations/${destination.id}/sync`,
      method: 'post'
    });
  }

  syncSource(e) {
    const { $widgetCard, source } = this.getSourceFromEvent(e);

    $.ajax({
      url: `/sources/${source.id}/sync`,
      method: 'post'
    });
  }

  importFolder(e) {
    this.syncSource(e);
  }

  openEditModal(e) {
    $('#loading-modal').modal();
    const { $widgetCard, connection } = this.getConnectionFromEvent(e);

    $.openAjaxModal(`/connections/${connection.id}/edit.json`, `Edit ${connection.description}`, null, { onShow: () => {
      $('#loading-modal').modal('hide');
      this.modules();
    }});
  }

  openDestinationEditModal(e) {
    $('#loading-modal').modal();
    const { $widgetCard, destination } = this.getDestinationFromEvent(e);

    $.openAjaxModal(`/destinations/${destination.id}/edit`, null, null, { onShow: () => {
      $('#loading-modal').modal('hide');
      this.modules();
    }});
  }

  inputChanged(e) {
    super.inputChanged(e);
  }

  updateConnectionWidgetCard(connectionId) {
    this.updateRemoteTemplate(`/connections/${connectionId}/widget_card`);
  }

  updateDestinationWidgetCardItem(destinationId) {
    this.updateRemoteTemplate(`/destinations/${destinationId}/widget_card_item`);
  }

  receiveWatchedFieldsBroadcasterEvent({ detail: { payload } }) {
    if(!payload.changed_fields ||
       !('syncing' in payload.changed_fields) ||
       payload.changed_fields.syncing == true) return;

    if (payload.model_name == 'Destination') {
      this.updateDestinationWidgetCardItem(payload.id);
    } else if(payload.model_name == 'Connection') {
      this.updateConnectionWidgetCard(payload.id);
    }
  }
}

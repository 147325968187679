import WidgetController from "controllers/widget_controller";
import DocumentsConcern from "controllers/concerns/documents";

export default class extends WidgetController {
  static targets = [
    'container',
    'loading',
    'empty',
    'pageInput',
    'bulkActions',
    'bulkSelectionToggler',
    'templatesLoading'
  ];

  static modelName = 'Document';
  static concerns = [DocumentsConcern];

  connect() {
    this.onConnect();
    this.loadedTemplates = false;

    // Little Helper to open the modal
    // setTimeout(this.showDocument, 1800)
  }

  isSearchable() {
    return Array.isArray(this.state.search.document_ids) &&
       this.state.search.document_ids?.length > 0;
  }

  setContainerInitiallyLoading() {}

  onShow() {
    this.loadActions();
  }

  loadActions() {
    if(this.loadedTemplates) return;

    API.ajax({
      url: 'exports/queue_destination_exports_templates',
      complete: ({ responseJSON: { actions, selected_destination_export_template_id }}) => {
        const $actions = $(actions);
        this.$templatesLoading.replaceWith($actions);
        this.loadedTemplates = true;

        setTimeout(() => {
          $actions.find(`[name='state[destination_export_template_id]']`).changeTo(JSON.stringify(selected_destination_export_template_id));
          this.$element.dispatch('exportQueueActionsReady', { bubbles: true });
        }, 100);
      }
    });
  }
}

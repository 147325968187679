import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  connect() {
    this.onConnect();
  }

  confirmAuth(e) {
    const $modal = $("#omniauth_google_drive_before_auth_modal");

    if($modal.length > 0) {
      $modal.on('shown.bs.modal', () => {
        const $button = $modal.find(".btn-primary");

        $button.on('click', (e) => {
          const $termsCheckbox = $modal.find("[name='terms_form[terms]']");
          const termsAccepted = $termsCheckbox.is(':checked');

          if(termsAccepted) {
            $("#omniauth_google_drive_before_auth_modal form")[0].submit();
          } else {
            $button.popover('show');
          }
        });

        $button.delay(1200).animate({ opacity: 1 });
      });

      $modal.modal();
      return;
    }

    if(this.state?.mode == 'pop') {
      gapi.load('auth2', () => {
        gapi.auth2.authorize({
          client_id: Wordable.config.GOOGLE_CLIENT_ID,
          cookie_policy: 'single_host_origin',
          scope: Wordable.config.GOOGLE_TOKEN_CLIENT_SCOPES,
          response_type: 'code'
        }, (response) => {
          this.auth(response);
        });
      });
    } else {
      const confirmedPath = this.$element.data('confirmed_path');
      window.location = confirmedPath;
    }
  }

  auth(gapiResponse) {
    if(!gapiResponse || gapiResponse.error) return;

    jQuery.ajax({
      type: 'POST',
      url: `${this.state.auth_url}/callback`,
      data: gapiResponse
    });
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = [
    "purchaseMessage",
    "priceMessage",
    "pricePerExportMessage",
    "discountMessage"
  ];

  connect() {
    this.onConnect();

    this.variables = {
      exports: 5,
      sites: 1,
      users: 1
    }
  }

  inputChanged(e) {
    const $target = $(e.target);
    const $knobWrapper = $target.closest('[data-knob]');
    const knob = $knobWrapper.data('knob');

    this.variables[knob.key] = $target.val();
    if(this.variables[knob.key] < knob.min){
      this.variables[knob.key] = knob.min;
    } else if(knob.max && this.variables[knob.key] > knob.max) {
      this.variables[knob.key] = knob.max;
    }

    this.updateUi();
  }

  buttonClick(e) {
    const $target = $(e.target);
    const $knobWrapper = $target.closest('[data-knob]');
    const knob = $knobWrapper.data('knob');
    const $input = $knobWrapper.find('input');
    const step = $target.data('step');

    this.variables[knob.key] += step;
    if(this.variables[knob.key] < knob.min){
      this.variables[knob.key] = knob.min;
    } else if(knob.max && this.variables[knob.key] > knob.max) {
      this.variables[knob.key] = knob.max;
    }

    this.updateUi();
  }

  updateUi() {
    let totalPrice = 0;

    this.$('[data-knob]').each((i, e) => {
      const $knobWrapper = $(e);
      const knob = $knobWrapper.data('knob');
      const value = this.variables[knob.key];
      const unitPriceValue = Math.min(value - knob.min, knob.final_value);
      const unitPrice = knob.a * unitPriceValue + knob.b;
      const price = unitPrice * value;
      totalPrice += price;
      $knobWrapper.find('input').val(this.variables[knob.key]);
      $knobWrapper.find('.price-wrapper').text(`${this.price(price)}/mo`);
      $knobWrapper.find('.unit-price-wrapper').text(`${this.price(unitPrice)}/${knob.key}`);
    });

    this.$('.total-final-price').text(this.price(totalPrice));
  }

  price(value) {
    return '$'+(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
}

import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['wordpress', 'destinationOptions', 'helpPrompt']

  connect() {
    this.onConnect();

    this.resetHelpPromptTimeout();
  }

  disconnect() {
    this.clearHelpPromptTimeout();
  }

  showHelpPrompt() {
    this.helpPromptTimeoutTriggered = true;
    if(!this.$helpPrompt) return;

    this.$helpPrompt.show();
    this.$helpPrompt.lookAtMe();
  }

  resetHelpPromptTimeout() {
    if(this.helpPromptTimeoutTriggered) return;
    if(this.helpPromptTimeout) clearTimeout(this.helpPromptTimeout);
    this.helpPromptTimeout = setInterval(this.showHelpPrompt, 10000);
  }

  showDestinationOptions() {
    ga4TrackEvent({
      target: 'onboarding_step_1_show_destination_options'
    });

    this.$wordpress.fadeOut(this.fadeInDestinationOptions)
  }

  fadeInDestinationOptions() {
    this.$destinationOptions.fadeIn();
  }

  showWordpress() {
    this.$destinationOptions.fadeOut(this.fadeInWordpress)
  }

  fadeInWordpress() {
    this.$wordpress.fadeIn();
  }
}

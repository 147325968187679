import ApplicationController from "controllers/application_controller"
import ExportOptionsFormConcern from "controllers/concerns/export_options_form";

export default class extends ApplicationController {
  static concerns = [ExportOptionsFormConcern];
  static targets = [
    'exportOptionsFormWrapper',
    'exportOptionsForm',
    'empty',
    'loading',
    'createDestinationExportTemplate',
    'updateDestinationExportTemplate',
    'destinationExportTemplateCreateButton',
    'destinationExportTemplateUpdateButton',
    'sidebarLoading',
    'sidebar'
  ];

  connect() {
    this.onConnect();
    this.stimulusController = 'destination_export_templates';
    this.optionsFormController = 'destination_export_templates';
    this.exportOptionsStateKey = 'destination_export_template';

    this.$destinationExportTemplateUpdateButton.on('click', this.updateDestinationExportTemplate);
    this.$destinationExportTemplateCreateButton.on('click', this.createDestinationExportTemplate);

    this.loadSidebar();
    this.setupForm();
  }

  // Sidebar
  loadSidebar() {
    this.$sidebarLoading.show();
    this.$sidebar.html('');

    API.ajax({
      url: `/destination_export_templates/sidebar.json`,
      method: 'GET',
      complete: ({ responseJSON }) => {
        this.$sidebar.html(responseJSON.sidebar);
        this.$sidebarLoading.hide();
      }
    });
  }

  confirmDestroy(e) {
    e.stopPropagation();
    const { $widgetCard, destinationExportTemplate } = this.getDestinationExportTemplateFromEvent(e);
    const detailText = `<div class="text-sm mt-h text-gray-500 italic">This action cannot be undone.</div>`;

    dataConfirmModal.confirm({
      text: `<div class="text-red-base text-lg mb-h">You are about to delete "${destinationExportTemplate.name}"</div> ${detailText}`,
      onConfirm: () => {
        this.destroy($widgetCard, destinationExportTemplate.id);
      },
    });
  }

  destroy($widgetCard, destinationExportTemplateId) {
    this.disableNode($widgetCard);

    API.ajax({
      url: `/destination_export_templates/${destinationExportTemplateId}`,
      method: 'DELETE',
      complete: () => {
        this.loadSidebar();
        $widgetCard.remove();

        if(this.state.destination_export_template_id == destinationExportTemplateId) {
          this.setIds();
        }
      }
    });
  }

  getDestinationExportTemplateFormAttributes() {
    return Object.assign(
      {},
      this.state.destination_export_template,
      this.getBaseDestinationExportTemplateFormAttributes()
    );
  }

  getDestinationExportTemplateFromEvent(e) {
    const $target = $(e.target);

    const $widgetCard = $target.closest(`[data-destination-export-template]`);
    const destinationExportTemplate = $widgetCard.data('destination-export-template');

    return { $widgetCard, destinationExportTemplate };
  }

  getDestinationFromEvent(e) {
    const $target = $(e.target);

    const $widgetCard = $target.closest(`[data-destination]`);
    const destination = $widgetCard.data('destination');

    return { $widgetCard, destination };
  }

  edit(e) {
    const { destinationExportTemplate } = this.getDestinationExportTemplateFromEvent(e);
    this.setIds(destinationExportTemplate.destination_id, destinationExportTemplate.id);
  }

  createNew(e) {
    const { destination } = this.getDestinationFromEvent(e);
    this.setIds(destination.id);
  }

  setIds(destinationId = "", destinationExportTemplateId = "") {
    this.$('input[name="state[destination_id]"]')
      .changeTo(destinationId);

    this.$(`input[name="state[destination_export_template_id]"]`)
      .changeTo(destinationExportTemplateId);

    this.setupForm();
  }

  onAfterFormSetup() {
    if(!this.state.destination_id) return;

    if(this.state.destination_export_template_id) {
      this.$createDestinationExportTemplate.hide();
      this.$updateDestinationExportTemplate.show();
    } else {
      this.$createDestinationExportTemplate.show();
      this.$updateDestinationExportTemplate.hide();
    }
  }

  afterSaveDestinationExportTemplate({ responseJSON }) {
    this.enableElement();

    this.loadSidebar();
    this.setIds(responseJSON.destination_export_template.destination_id,
                responseJSON.destination_export_template.id);
  }
}

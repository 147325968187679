import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  connect() {
    this.onConnect();
  }

  look() {
    $(this.state.thing_selector).lookAtMe();
  }
}

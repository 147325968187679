import ChromeExtensionController from "controllers/chrome_extension_controller";

export default class extends ChromeExtensionController {
  connect() {
    this.onConnect();
    this.layout();
    this.setFilterToAValueThatShowsSomething();

    chrome.runtime.onMessage.addListener(this.receiveMessage);
    this.ensureServiceWorkerWebSocket();
  }

  setFilterToAValueThatShowsSomething() {
    const documentsByExportStatus = {};

    this.$('.google-drive-document').each((index, widgetCard) => {
      const $widgetCard = $(widgetCard);
      const widgetCardData = $widgetCard.data();

      if(documentsByExportStatus[widgetCardData.externalDocument.export_status]) {
        documentsByExportStatus[widgetCardData.externalDocument.export_status] += 1;
      } else {
        documentsByExportStatus[widgetCardData.externalDocument.export_status] = 1;
      }
    });

    const exportStatus = Object.keys(documentsByExportStatus)[0];
    if(exportStatus == null) return;

    this.changeFieldTo("state[search][export_status]", exportStatus);
  }

  layout() {
    const documentsByStatus = { all: 0 };

    this.$('.google-drive-document').each((index, widgetCard) => {
      const $widgetCard = $(widgetCard);
      const widgetCardData = $widgetCard.data();

      if(this.state.search.export_status != 'all' && widgetCardData.externalDocument.export_status != this.state.search.export_status) {
        $widgetCard.hide();
      } else {
        $widgetCard.show();
        documentsByStatus.all += 1;

        if(documentsByStatus[widgetCardData.externalDocument.status]) {
          documentsByStatus[widgetCardData.externalDocument.status] += 1;
        } else {
          documentsByStatus[widgetCardData.externalDocument.status] = 1;
        }
      }
    });

    new Masonry($('.widget')[0], {
      itemSelector: '.google-drive-document',
      gutter: 8,
      horizontalOrder: true
    });

    this.$('.external-document-status-badge-count-wrapper').hide();

    Object.entries(documentsByStatus).forEach(([status, count]) => {
      if(count > 0) {
        const $badgeCounterWrapper = this.$(`.external-document-status-badge-count-wrapper[data-external-document-status=${status}]`);

        $badgeCounterWrapper.find('.external-document-status-badge-count').text(count);

        $badgeCounterWrapper.show();
      }
    });

    installAll();
  }

  onStateSearchExportStatusChanged() {
    this.layout();
  }

  importVisible() {
    $('[data-external-document]').each((i, card) => {
      this.innerImportDocument($(card));
    });
  }

  importDocument(e) {
    const $card = $(e.target).closest('[data-external-document]');
    this.innerImportDocument($card);
  }

  innerImportDocument($card) {
    if($card.length == 0 || !$card.is(':visible')) return;

    this.disableNode($card);

    API.ajax({
      method: 'POST',
      url: `${Wordable.global.API_HOST}/imports/import_document`,
      data: {
        external_document: $card.data('external-document'),
        trigger: 'chrome_extension'
      }
    });
  }

  exportVisible() {
    const documentIds = [];

    this.$('.google-drive-document').each((index, widgetCard) => {
      const $widgetCard = $(widgetCard);
      const documentId = $widgetCard.data().externalDocument.imported_document_id;

      if(documentId && $widgetCard.is(':visible')) {
        documentIds.push(documentId);
      }
    });

    chrome.tabs.create({
      url: `${Wordable.global.API_HOST}/exports/new?document_ids=${documentIds.join(',')}`
    });
  }

  receiveWatchedFieldsBroadcasterEvent({ detail: { payload } }) {
    if (payload.model_name == 'Document') {
      const $currentWidgetCard = this.$(
        payload
          .templates
          .external_document
          .chrome_extension_google_drive_folder_document_widget_card
          .selector);

      if($currentWidgetCard.length > 0) {
        $currentWidgetCard.replaceWith(
          payload
            .templates
            .external_document
            .chrome_extension_google_drive_folder_document_widget_card
            .template);
      }

      this.layout();
    }
  }
}

class ClickablePopover {
  constructor($trigger) {
    this.$trigger = $trigger;

    if(this.$trigger.data('clickable-popover-installed')) {
      return;
    }

    this.$trigger.data('clickable-popover-installed', true);

    this.showPopover = this.showPopover.bind(this);
    this.startHidePopoverTimeout = this.startHidePopoverTimeout.bind(this);
    this.clearHidePopoverTimeout = this.clearHidePopoverTimeout.bind(this);
    this.hidePopover = this.hidePopover.bind(this);

    this.$trigger.on('mouseenter', this.showPopover);
    this.$trigger.on('mouseleave', this.startHidePopoverTimeout);
  }

  showPopover(e) {
    this.$trigger.popover('show');
    this.clearHidePopoverTimeout();

    if(!this.popoverId) {
      this.popoverId = this.$trigger.attr('aria-describedby');
      this.$popover = $(`#${this.popoverId}`);
      this.$popover.on('mouseenter', this.showPopover);
      this.$popover.on('mouseleave', this.startHidePopoverTimeout);
    }
  }

  hidePopover() {
    this.$trigger.popover('hide');
    this.clearHidePopoverTimeout();
  }

  startHidePopoverTimeout() {
    this.hidePopoverTimeout = setTimeout(this.hidePopover, 300);
  }

  clearHidePopoverTimeout() {
    clearTimeout(this.hidePopoverTimeout);
  }
}

function installAll() {
  $('[data-toggle="popover"]').attr('data-html', 'true').popover({ sanitize: false });
  $('[data-toggle="popover"]').popover();
  $('[data-toggle="collapse"]').collapse();
  $('input[data-role=tagsinput]').tagsinput();
  $('[data-toggle="popover"][data-clickable]').each((i, trigger) => {
    new ClickablePopover($(trigger));
  });
}

export default installAll;

export default {
  replaceState(queryMutation) {
    var newUrl = this.mutateUrl(queryMutation);
    window.history.replaceState({ path: newUrl }, '', newUrl);
    window.dispatchEvent(new CustomEvent('replaceState', { detail: { queryMutation: queryMutation }}));
  },

  goTo(queryMutation) {
    window.location = this.mutateUrl(queryMutation);
  },

  withSearch(url, searchPart) {
    if(!searchPart || searchPart.length == 0) return url;

    searchPart = searchPart.replaceAll('%2C', ',');
    if(searchPart.length == 0) return url;

    return `${url}?${searchPart}`;
  },

  withCurrentSearch(url, queryMutation = {}) {
    return HREF.withSearch(url, (HREF.mutateCurrentSearch(queryMutation)).toString());
  },

  mutateUrl(queryMutation) {
    var searchParams = new URLSearchParams(window.location.search);

    Object.entries(queryMutation).forEach(([name, value]) => {
      if(value == null) {
        searchParams.delete(name);
      } else {
        searchParams.set(name, value);
      }
    });

    return HREF.withSearch(window.location.protocol + "//" + window.location.host + window.location.pathname,
                           this.mutateCurrentSearch(queryMutation).toString());
  },

  mutateCurrentSearch(queryMutation) {
    let searchParams = new URLSearchParams(window.location.search);

    Object.entries(queryMutation).forEach(([name, value]) => {
      if(value == null) {
        searchParams.delete(name);
      } else {
        searchParams.set(name, value);
      }
    });

    return searchParams;
  },

  get(name, def = null) {
    var searchParams = new URLSearchParams(window.location.search);

    if(searchParams.has(name)) return searchParams.get(name);
    return def;
  },

  state() {
    var state = {};

    for (const [name, value] of (new URLSearchParams(window.location.search)).entries()) {
      state[name] = value;
    }

    return state;
  },

  toQuery() {
    return (new URLSearchParams(window.location.search)).toString();
  }
}

import "./vendor/tagsinput";

jQuery.fn.baseRemove = jQuery.fn.remove;

jQuery.fn.extend({
  // TODO: Remove this
  live: function (event, callback) {
    if (this.selector) {
      jQuery(document).on(event, this.selector, callback);
    }
    return this;
  },

  remove: function(...params) {
    const $this = $(this);
    $this.find('[data-toggle="popover"]').popover('hide');
    $this.baseRemove(...params);
  },

  fadeOutAndRemove: function(onComplete) {
    $(this).fadeOut(function(){
      $(this).remove();
      if (typeof onComplete == 'function') {
        onComplete() ;
      }
    })
  },

  lookAtMe: function() {
    this.addAndRemoveClass('look-at-me');
  },

  keepLookingAtMe: function() {
    this.addClass('look-at-me');
  },

  stopLookingAtMe: function() {
    this.removeClass('look-at-me');
  },

  blink: function() {
    this.addAndRemoveClass('opacity-05', 300);
  },

  addAndRemoveClass: function(className, delay = 1300) {
    this.addClass(className).delay(delay).queue(function() {
      $(this).removeClass(className).dequeue();
    });
  },

  changeTo: function(val) {
    if(this.length < 1) return;

    if(this.is('[type=checkbox]')) {
      this.prop('checked', val);
    } else {
      this.val(val);
    }

    this[0].dispatchEvent(new Event('change'));
  },

  dispatch: function(name, options = {}) {
    const event = new CustomEvent(name, options);
    this[0].dispatchEvent(event);
  },

  replaceWith: function(html) {
    this.after(html);
    this.remove();
  },

  outerHtml: function() {
    return $('<div />').append(this.eq(0).clone()).html();
  },

  getState: function() {
    if(this.length < 1) return {};

    return {
      selectionStart: this[0].selectionStart,
      selectionEnd: this[0].selectionEnd,
      isFocus: this.is(':focus')
    }
  },

  setState: function({ selectionStart, selectionEnd, isFocus }) {
    if(this.length < 1) return {};

    if(isFocus) this.focus();

    this[0].selectionStart = selectionStart;
    this[0].selectionEnd = selectionEnd;
  }
});

jQuery.extend({
  openAjaxModal: function(url, title, footer, options = {}) {
    $.get(url, function(response) {
      let body = '';

      if(typeof response === 'string') {
        body = response;
      } else {
        body = response.html;
        title = title ? title : response.title;
        footer = footer ? footer : response.footer;
      }

      $.openModal({ title, body, footer, ...options });
    });
  },

  crossFade: function($nodeToHide, $nodeToShow, onComplete) {
    $nodeToHide.fadeOut(() => {
      return $nodeToShow.fadeIn(() => {
        if(onComplete) onComplete()
      });
    })
  },

  openModal: function({ title, body, footer, onShow, selector = '#default-modal' }) {
    const modal = $(selector);

    if(title) modal.find('.modal-title').html(title);
    if(body) modal.find('.modal-body').html(body);

    if(footer) {
      modal.find('.modal-footer').html(footer);
      modal.find('.modal-footer').show();
    } else {
      modal.find('.modal-footer').hide();
    }

    modal.modal();

    if(onShow) onShow(modal);
  },

  infoModal: function(options) {
    $.openModal({ ...options, selector: '#info-modal' })
  }
});

import "./vendor/jquery-ui.min";

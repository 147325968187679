import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  connect() {
    this.onConnect();

    document.addEventListener(
      "WatchedFieldsBroadcaster",
      this.receiveWatchedFieldsBroadcasterEvent
    );

    this.interval = setInterval(this.checkCurrentTeam, 2000);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  receiveWatchedFieldsBroadcasterEvent({ detail: { payload } }) {
    if (payload.model_name == 'Team') {
      this.checkRedirect(payload);
    }
  }

  checkCurrentTeam() {
    API.ajax({
      url: `/current_team`,
      method: 'GET',
      complete: ({ responseJSON }) => {
        this.checkRedirect(responseJSON.team);
      }
    })
  }

  checkRedirect(team) {
    if(team.signup_subscription == this.state.expected_signup_subscription) return;

    window.location = '/';
  }
}

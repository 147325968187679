import toastr from "toastr/toastr";

toastr.options = {
  positionClass: "toast-bottom-right"
};

toastr.notice = toastr.success;
toastr.alert = toastr.error;

window.toastr = toastr;

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['placeholder', 'input'];

  connect() {
    this.onConnect();

    this.detachInput();
  }

  detachInput() {
    if(!this.state.tokenPresent) return;

    this.$detachedInput = this.$input.detach();
  }

  reatachInput() {
    if(!this.$detachedInput) return;

    this.$element.append(this.$detachedInput);
    this.$detachedInput.show();
  }

  show() {
    this.reatachInput();
    this.$placeholder.hide();
  }

  hide() {
    this.detachInput();
    this.$placeholder.show();
  }
}

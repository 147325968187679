import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['fieldTemplateWrapper'];

  connect() {
    this.onConnect();
    this.lastIndex = 0;
    this.values = JSON.parse(JSON.stringify(this.state.values));
    this.values.push('');

    this.discoverFieldTemplate();
    this.populateFields();
    // this.setCurrentFields();
    // this.discoverFieldTemplate();
  }

  discoverFieldTemplate() {
    this.$fieldTemplate = $(this.$fieldTemplateWrapper.html());
    this.$fieldTemplateWrapper.html('');
  }

  populateFields() {
    this.currentFields = [];

    this.values.forEach((value) => {
      this.addField({ value: value });
    })
  }

  addField(attributes) {
    const $field = this.$fieldTemplate.clone();
    const index = this.lastIndex;
    this.lastIndex += 1;

    const newName = $field.attr('name').replace('$index', index);

    this.$fieldTemplateWrapper.before($field);
    $field.val(attributes.value);
    $field.attr('name', newName);
    this.currentFields.push($field);
  }

  getLastField() {
    if(this.currentFields.length == 0) return null;

    return this.currentFields[this.currentFields.length - 1];
  }

  isLastField($field) {
    const lastFieldName = this.getLastField().attr('name');
    return lastFieldName == $field.attr('name');
  }

  repeatableFieldKeyup(e) {
    const $changedField = $(e.target);

    if($changedField.val() == '') this.removeField($changedField);
    if(this.getLastField().val() != '') this.addField({ value: '' });
  }

  repeatableFieldChanged(e) {
    const $changedField = $(e.target);

    if($changedField.val() == '')
      this.removeField($changedField);
  }

  removeField($field) {
    const isLastField = this.isLastField($field);
    if(isLastField) return;

    this.getLastField().focus();
    $field.fadeOutAndRemove();
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "submit", "name", "file" ]

  connect() {
    // TODO
  }

  changeName(event) {
    this.checkSubmitButton();
  }

  selectFile(event) {
    const file = event.target.files[0];
    if (file.type == 'text/html' || file.type == 'application/zip') {
      $('#selected_file_name').html(`${file.name} <i class="fa fa-check text-success"></i>`);
      this.checkSubmitButton();
    } else {
      $('#selected_file_name').html("<span class='text-danger'>Only HTML files or zip files containing HTML files and images are allowed.</span>");
    }
  }

  selectImage(event) {
    const file = event.target.files[0];
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (validImageTypes.includes(file.type)) {
      var output = document.getElementById('profile_avatar');
      output.src = URL.createObjectURL(file);
      output.onload = function() {
        URL.revokeObjectURL(output.src) // free memory
      }
      $('#selected_file_name').html('Uploading <i class="fa fa-spinner fa-pulse"></i>');
      Rails.fire($('#avatar-form')[0], 'submit')
    } else {
      $('#selected_file_name').html("<span class='text-danger'>Only GIF/JPEG/PNG file is acceptable.</span>");
    }
  }

  checkSubmitButton() {
    if (this.nameTarget.value && this.fileTarget.value) {
      this.submitTarget.removeAttribute('disabled');
    } else {
      this.submitTarget.setAttribute('disabled', true);
    }
  }
}

import ApplicationController from "controllers/application_controller"

export default class extends ApplicationController {
  static targets = ['errorMessage'];

  connect() {
    this.onConnect();
  }

  setError({ detail: { error } }) {
    if(error && error.length > 0) {
      this.$element.addClass('with-error');
      return this.$errorMessage.html(error);
    }

    this.clear();
  }

  clear() {
    this.$element.removeClass('with-error');
    return this.$errorMessage.html(null);
  }
}

import ApplicationController from "controllers/application_controller"
import Cookies from 'js-cookie';

export default class extends ApplicationController {
  static targets = [
    'setSiteInput',
    'setSiteFormGroup',
    'setSiteUrlButton',
    'websiteHostInputWrapper',
    'websiteHostDisplayWrapper',
    'websiteHostDisplay',
    'installLink',
    'activateLink',
  ];

  connect() {
    this.onConnect();

    const websiteHost = Cookies.get('onboardingWordPressSiteUrl');
    if(websiteHost) {
      this.$setSiteInput.changeTo(websiteHost);
    } else {
      this.updateUi();
    }

    // this.$setSiteInput.changeTo('test.wordable.io');
  }

  setWebsiteHost() {
    this.$setSiteInput.changeTo(this.$setSiteInput.val());
  }

  connectAnotherSite() {
    ga4TrackEvent({
      version: this.state.version,
      target: 'wordpress_instructions_connect_another_site'
    });

    Cookies.remove('onboardingWordPressSiteUrl');
    HREF.replaceState({ step: null });
    this.$setSiteInput.val(this.parsedWebsiteHost);
    this.parsedWebsiteHost = null;
    this.state.websiteHost = null;
    this.updateUi();
  }

  onStateWebsiteHostChanged() {
    this.disableElement();
    let websiteHost = this.state.websiteHost;

    this.$setSiteFormGroup.dispatch('clear');

    API.ajax({
      url: `/wordpress/check_connect_url?url=${websiteHost}`,
      complete: ({ responseJSON }) => {
        if(responseJSON.parsed_url) {
          this.state.error = null;
          this.parsedWebsiteHost = responseJSON.parsed_url;
          Cookies.set('onboardingWordPressSiteUrl', this.parsedWebsiteHost);

          const installPageUrl = new URL(responseJSON.parsed_url);
          const pluginSettingsPageUrl = new URL(responseJSON.parsed_url);

          installPageUrl.pathname = installPageUrl.pathname + `/plugin-install.php`.replaceAll('//', '/');
          installPageUrl.search = 's=Wordable&tab=search&type=term';

          pluginSettingsPageUrl.pathname = installPageUrl.pathname.replaceAll('//', '/');
          pluginSettingsPageUrl.search = 'page=wordable-plugin';

          this.state.targets = {
            install_page: installPageUrl.toString(),
            plugin_settings_page: pluginSettingsPageUrl.toString()
          };
        } else {
          this.state.error = responseJSON.message;
        }

        ga4TrackEvent({
          version: this.state.version,
          target: 'wordpress_instructions_website_changed',
          variant: (this.state.error ? 'error' : 'success'),
          value: this.parsedWebsiteHost
        });

        this.enableElement();
        this.updateUi();
      }
    })
  }

  updateUi() {
    this.step = HREF.get('step');
    if(this.step != 'activation') this.step = 'installation';

    if(this.state.error || !this.state.websiteHost) {
      this.$websiteHostInputWrapper.show();
      this.$websiteHostDisplayWrapper.hide();

      if(this.state.error) {
        this.$setSiteFormGroup.dispatch('error', { detail: { error: this.state.error }});
      } else {
        this.$setSiteFormGroup.dispatch('clean');
      }

      this.$('.host-presence-instructions-visible').hide();
    } else if(this.state.websiteHost) {
      this.$websiteHostInputWrapper.hide();
      this.$websiteHostDisplayWrapper.show();
      this.$websiteHostDisplay.text(this.parsedWebsiteHost);
      this.$('.host-presence-instructions-visible').show();
      // this.$installLink.attr('href', this.state.targets.install_page);
      // this.$activateLink.attr('href', this.state.targets.plugin_settings_page);

      if(this.step == 'installation') {
        this.$('.installation-instructions-visible').fadeIn();
        this.$('.activation-instructions-visible').hide();
      } else if(this.step == 'activation') {
        this.$('.installation-instructions-visible').hide();
        this.$('.activation-instructions-visible').fadeIn();
      }
    }

    this.$element.dispatch('resetIntercomTimeout', { bubbles: true });
  }

  go(e) {
    const $target = this.$(e.target).closest('[data-go-target]')
    const target = $target.data('go-target');

    if(!target) return;

    const url = this.state.targets[target];

    if(!url) return;

    ga4TrackEvent({
      version: this.state.version,
      target: `wordpress_instructions_go_to_${target}`
    });

    window.open(url);
    this.showActivationInstructions();
  }

  showInstallationInstructions() {
    HREF.replaceState({ step: null });
    // const searchParams = new URLSearchParams(window.location.search);
    // searchParams.delete('step');

    // var searchPart = searchParams.toString();

    // if(searchPart.length > 0) {
    //   searchPart = `?${searchPart}`;
    // }

    // var newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + searchPart;
    // window.history.replaceState({ path: newUrl }, '', newUrl);

    this.step = 'installation';
    this.updateUi();
  }

  showActivationInstructions() {
    HREF.replaceState({ step: 'activation' });

    // const searchParams = new URLSearchParams(window.location.search);
    // searchParams.set('step', 'activation');
    // var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
    // window.history.replaceState({ path: newUrl }, '', newUrl);

    this.step = 'activation';
    this.updateUi();
  }
}

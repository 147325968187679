class GoogleDrivePicker {
  constructor(config) {
    this.config = config;
    this.onPick = this.onPick.bind(this);
    this.onReceiveToken = this.onReceiveToken.bind(this);
  }

  show() {
    if(this.config.request_picker_auth) {
      GoogleTokenClient.callback = this.onReceiveToken
      GoogleTokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      if(!this.picker) {
        this.picker = new google.picker.PickerBuilder()
          .addView(google.picker.ViewId.DOCUMENTS)
          .setOAuthToken(this.config.access_token)
          .setDeveloperKey(Wordable.config.GOOGLE_DRIVE_PICKER_API_KEY)
          .setCallback(this.onPick)
          .setAppId(Wordable.config.GOOGLE_CLIENT_ID)
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .build();
      }

      this.picker.setVisible(true);
    }
  }

  onReceiveToken({ access_token, expires_in, scope }) {
    $('#loading-modal').modal();
    this.config.access_token = access_token;
    this.config.request_picker_auth = false;

    API.ajax({
      url: `/connections/${this.config.connection_id}`,
      method: 'PUT',
      data: {
        connection: {
          token: access_token,
          token_expires_in: expires_in,
          permissions_scope: scope
        }
      },
      complete: () => {
        this.show();
        $('#loading-modal').modal('hide');
      }
    });
  }

  onPick({ action, docs }) {
    if(action != 'picked') return;

    docs.forEach((doc) => {
      this.config.onPick({
        id: doc.id,
        name: doc.name,
        url: doc.url,
        connection_id: this.config.connection_id
      });
    });
  }
}

export default GoogleDrivePicker;

export default class WistiaVideoAdapter {
  constructor(videoId, $playerWrapper, options = {}) {
    this.options = options;

    Object.getOwnPropertyNames(Object.getPrototypeOf(this)).forEach((propertyName) => {
      if(typeof this[propertyName] == 'function' && propertyName != 'constructor') {
        this[propertyName] = this[propertyName].bind(this);
      }
    });

    this.videoId = videoId;
    this.$playerWrapper = $playerWrapper;
    this.initPlayer();
    this.player = null;
  }

  initPlayer() {
    if(typeof Wistia == 'undefined') return setTimeout(this.initPlayer, 200);

    const divId = `${this.videoId}-player`;
    if(this.$playerWrapper.find(`#${divId}`).length == 0) {
      const videoParams = this.$playerWrapper.data('video');
      let width = videoParams.width || 640;
      let height = videoParams.height || 360;

      if(this.options.wrapperWidth && this.options.wrapperWidth < width) {
        const ratio = this.options.wrapperWidth / width;
        width = this.options.innerWidth;
        height = height * ratio;
      }

      this.$playerWrapper
        .append(`<div id="${divId}" style="width: ${width}px; height: ${height}px;" class="wistia_embed wistia_async_${this.videoId}"></div>`);
    }

    this.player = Wistia.api(this.videoId);
    if(!this.player) return setTimeout(this.initPlayer, 200);
    this.player.bind('play', this.onPlay);
  }

  onPlay() {
    ga4TrackEvent({ target: 'video_tutorial_play', video_id: this.videoId });
  }

  play(seek = 0) {
    if(typeof seek == 'number') this.player.time(seek);
    this.player.play();
    this.player.volume(100);
  }

  stop() {
    // this.player.stopVideo();
  }

  pause() {
    this.player.pause();
  }

  // onPlayerReady() {}

  // onPlayerStateChange() {}
}

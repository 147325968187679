import ApplicationController from "controllers/application_controller";

export default class extends ApplicationController {
  onConnect() {
    super.onConnect();

    this.modelName = this.constructor.modelName;
    this.searchUrl = `/${this.pluralUnderscoreName}/search`;
    this.selectedWidgetCards = [];

    document.addEventListener(
      "WatchedFieldsBroadcaster",
      this.receiveWatchedFieldsBroadcasterEvent
    );

    this.setContainerInitiallyLoading();
    this.search();
  }

  disconnect() {
    document.removeEventListener("WatchedFieldsBroadcaster", this.receiveWatchedFieldsBroadcasterEvent);
  }

  confirmBulkDestroy() {
    if(this.selectedWidgetCards.length == 0) return;

    const titleText = this.selectedWidgetCards.length == 1 ? `this ${this.singularUnderscoreName}` : `these ${this.pluralUnderscoreName}`;
    const instancesNamesHtml = this.selectedWidgetCards.map((instance) => {
      return `<p>${instance.name}</p>`;
    }).sort().join("\n");

    const detailText = this.constructor.deleteDetailText ?
          `<div class="text-sm mt-h text-gray-500 italic">${this.constructor.deleteDetailText}</div>` : '';

    dataConfirmModal.confirm({
      text: `<div class="text-red-base text-lg mb-h">You are about to delete ${titleText}</div> ${instancesNamesHtml} ${detailText}</div>`,
      onConfirm: this.bulkDestroy,
    });
  }

  bulkDestroy() {
    this.disableElement();

    API.ajax({
      url: `/${this.pluralUnderscoreName}`,
      method: 'delete',
      data: {
        [`${this.singularUnderscoreName}_ids`]: this.selectedWidgetCards.map((instance) => {
          return instance.id;
        })
      },
      complete: this.search
    });
  }

  widgetCards() {
    return this.$element.find('.widget-card');
  }

  selectableWidgetCards() {
    return this.$element.find('.widget-card[data-selectable]');
  }

  selectableWidgetCardCount() {
    return this.selectableWidgetCards().length;
  }

  toogleBulkSelection() {
    if(this.anyUnselectedSelectableWidgetCard()) {
      this.bulkSelectAllWidgetCards();
    } else {
      this.clearWidgetCardsSelection();
    }
  }

  toggleSelected(e) {
    const $target = $(e.target);

    // Duct tape, we should have a better way of doing this :(
    if ($target.closest('[data-stop-propagation]').length > 0) return;

    const $widgetCard = $target.closest(`[data-${this.singularUnderscoreName}-id]`);
    const instanceId = parseInt($widgetCard.data(`${this.singularUnderscoreName}-id`));
    const isSelectable = $widgetCard.is('[data-selectable]');

    if(!instanceId || !isSelectable) return;

    const selectedInstanceIndex = this.selectedWidgetCards.findIndex((instance) => {
      return instance.id == instanceId;
    });

    if (selectedInstanceIndex == -1) {
      this.selectWidgetCard($widgetCard);
    } else {
      $widgetCard.removeClass('widget-card-selected');
      this.selectedWidgetCards.splice(selectedInstanceIndex, 1);
    }

    this.ensureBulkActionsState();
  }

  removeFromSelection(e) {
    const $target = $(e.target);
    const $widgetCard = $target.closest(`[data-${this.singularUnderscoreName}-id]`);
    const instanceId = parseInt($widgetCard.data(`${this.singularUnderscoreName}-id`));

    const selectedInstanceIndex = this.selectedWidgetCards.findIndex((instance) => {
      return instance.id == instanceId;
    });

    if(selectedInstanceIndex != -1) {
      $widgetCard.removeClass('widget-card-selected');
      this.selectedWidgetCards.splice(selectedInstanceIndex, 1);
    };

    this.ensureBulkActionsState();
  }

  bulkSelectAllWidgetCards() {
    this.selectableWidgetCards().each((index, widgetCard) => {
      return this.selectWidgetCard($(widgetCard));
    })

    this.ensureBulkActionsState();
  }

  clearWidgetCardsSelection() {
    this.$element.find('.widget-card-selected').removeClass('widget-card-selected');
    this.selectedWidgetCards = [];
    this.ensureBulkActionsState();
  }

  selectWidgetCard($widgetCard) {
    const instanceId = $widgetCard.data(`${this.singularUnderscoreName}-id`);
    const instanceAttributes = $widgetCard.data(`${this.singularUnderscoreName}`);
    $widgetCard.addClass('widget-card-selected');
    this.selectedWidgetCards.push(instanceAttributes);
  }

  anyUnselectedSelectableWidgetCard() {
    const selectableWidgetCardCount = this.selectableWidgetCardCount()
    return selectableWidgetCardCount > 0 && selectableWidgetCardCount > this.selectedWidgetCards.length;
  }

  ensureBulkActionsState() {
    if(!this.$bulkActions) return;

    if(this.selectedWidgetCards.length == 0) {
      this.disableNode(this.$bulkActions);
    } else {
      this.enableNode(this.$bulkActions);
    }

    if(this.anyUnselectedSelectableWidgetCard()) {
      this.$bulkSelectionToggler.text('Select All');
    } else {
      this.$bulkSelectionToggler.text('Clear');
    }
  }

  setContainerInitiallyLoading() {
    this.$container.hide();
    this.$empty.hide();
    this.$container.html('');
    this.$loading.fadeIn();
  }

  search() {
    if(!this.isSearchable()) return;

    this.disableContainer();

    $.ajax({
      url: this.searchUrl,
      data: this.state.search,
      success: this.handleSearchSuccess,
      complete: this.enableAll
    });
  }

  isSearchable() {
    return true;
  }

  enableAll() {
    this.enableContainer();
    this.enableElement();
  }

  disableContainer() {
    this.disableNode(this.$container);
  }

  enableContainer() {
    this.enableNode(this.$container);
  }

  clearSearchTimeoutAndSearch() {
    if(this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.search();
  }

  timeoutedSearch() {
    if(this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(this.search, 500);
  }

  setPage(e) {
    this.$pageInput.val($(e.target).data('page'));
    this.$pageInput[0].dispatchEvent(new Event('change'));
  }

  handleSearchSuccess(response) {
    this.clearWidgetCardsSelection();
    this.$loading.hide();
    this.$container.html(response.template);

    if(response[this.pluralUnderscoreName].length > 0) {
      this.$container.show();
      this.$empty.hide();
    } else {
      this.$container.hide();
      this.$empty.show();
    }

    this.modules();
    this.ensureBulkActionsState();

    // Little helpers

    // Work with the "selected to export"
    // setTimeout(() => {
    //   return $('.document-export-action [data-action="dashboard#selectToExport"]').trigger('click');
    // }, 200);

    // Work with the documents carrousel
    // setTimeout(() => {
    //   return $('[data-template-id="document-widget-card_15214"] .widget-card-link').trigger('click');
    // })
  }
}
